import { ADD_EDITED_EDGE, DELETE_EDITED_EDGE } from '../actions/actions';

export const initialState = [];

const editedEdgesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EDITED_EDGE: {
      return [
        ...state,
        action.payload.id,
      ];
    }
    case DELETE_EDITED_EDGE: {
      return [
        ...state.map(item => {
          if (String(item._id) === action.payload?._id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        })
      ];
    }
    default: {
      return state;
    }
  }
};

export { editedEdgesReducer };