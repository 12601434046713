import { UPDATE_NODE_POSITION, GET_NODE_POSITION } from '../actions/actions';

export const initialState = [];

const nodesPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NODE_POSITION: {
      return action.payload;
    }
    case GET_NODE_POSITION: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export { nodesPositionReducer };