import { LOAD_ACTIVE_QUESTION, UPDATE_ACTIVE_QUESTION } from "../actions/actions";

export const initialState = {};

export const activeQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACTIVE_QUESTION: {  
      return { ...action.payload };
    }
    case UPDATE_ACTIVE_QUESTION: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}