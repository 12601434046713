import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import QuestionCustomization from './QuestionCustomization';
import { getStaticText } from '../../../assets/json/component-static-text/index';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          height={'86vh'}
          paddingTop='40px'
          className="scroll-content"
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function QuestionCustomizationLayout() {
  const [activeTab, setActiveTab] = React.useState(0);
  const language = useSelector((state)=> state.language.lang)
  const staticTexts = getStaticText(language);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getActiveTabColor = (tab) => {
    return (tab === 'advance' && activeTab === 1) ||
      (tab === 'customize' && activeTab === 0)
      ? '#fff'
      : '#ffffff8c';
  };

  return (
    <>
      <div className="title-v1 sticky-header">{staticTexts['customizeBotMessages']}</div>
      <Box sx={{ width: '100%'}} position='relative'>
        <Box
          sx={{
            borderBottom: '1px solid #4D4D4D',
            position:'fixed',
            background : "#000",
            zIndex: "1000",
            width:'100%'
          }}
          // position='absolute'
          // top='0'
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            style={{ fontWeight: '200' }}
            TabIndicatorProps={{ style: { background: '#212529' } }}
          >
            <Tab
              label={staticTexts['customize']}
              {...a11yProps(0)}
              style={{
                color: getActiveTabColor('customize'),
                textTransform: 'capitalize',
              }}
            />
            <Tab
              label={staticTexts['advance']}
              {...a11yProps(1)}
              style={{
                color: getActiveTabColor('advance'),
                textTransform: 'capitalize',
              }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={activeTab}
          index={0}
        >
          <QuestionCustomization tab={'customize'} />
        </TabPanel>
        <TabPanel
          value={activeTab}
          index={1}
        >
          <QuestionCustomization tab={'advance'} />
        </TabPanel>
      </Box>
    </>
  );
}
