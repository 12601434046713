import { LOAD_AGENCY} from '../actions/actions';

export const initialState = {};

const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AGENCY: {
      return {...action.payload} ;
    }
    default: {
      return { ...state };
    }
  }
};

export { agencyReducer };