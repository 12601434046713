import './index.scss';
import update from 'immutability-helper';

import ChatBubble from '../Common/ChatBubble';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
  LOAD_ACTIVE_QUESTION,
  LOAD_FLOW,
  UPDATE_FLOW,
  UPDATE_NON_BLOCK_LOADER,
  LOAD_NEW_QUESTION,
  UPDATE_TOAST,
} from '../../../redux/actions/actions';
import { BotMethods } from '../../../services/bot';
import { v4 as uuid } from 'uuid';
import { Divider, Box, Typography } from '@mui/material';
import { plusIcon as Plus } from '../../../assets/icons';
import CircularProgress from '@mui/material/CircularProgress';
import ChatComponent from './component/chat-component';
import {
  showComponentAnimation,
  filterComponentByBot,
} from '../../../utilities/helper';
import { getStaticText } from '../../../assets/json/component-static-text/index';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getFlowTexts } from '../../../assets/json/flowTexts';

export default function FlowQuestions(props) {
  const language = useSelector((state)=> state.language.lang);
  const flowTexts = getFlowTexts(language)
  const staticTexts = getStaticText();
  const flow = useSelector((state) => state.flow);
  const newQuestionAdded = useSelector((state) => state.newQuestionAdded);
  const loaderNonBlock = useSelector((state) => state.loaderNonBlock);
  const { updateBot } = BotMethods();
  const [chatComponents, setChatComponents] = useState({
    list: [
      {
        key: 'statement',
        value: staticTexts['messageText'],
        category: ['frequentUsed', 'sendInfo'],
      },
      {
        key: 'name',
        value: staticTexts['nameText'],
        category: ['frequentUsed', 'requestInfo'],
      },
      {
        key: 'phone',
        value: staticTexts['phoneNumberText'],
        category: ['frequentUsed', 'requestInfo'],
      },
      {
        key: 'email',
        value: staticTexts['emailText'],
        category: ['frequentUsed', 'requestInfo'],
      },
      {
        key: 'button',
        value: staticTexts['singleChoiceText'],
        category: ['frequentUsed', 'requestInfo'],
      },
      {
        key: 'question',
        value: staticTexts['textQuestionsText'],
        category: ['frequentUsed', 'requestInfo'],
      },
      {
        key: 'file',
        value: staticTexts['fileText'],
        category: ['requestInfo'],
      },
      {
        key: 'location',
        value: staticTexts['locationText'],
        category: ['requestInfo'],
      },
      {
        key: 'date',
        value: staticTexts['dateText'],
        category: ['requestInfo'],
      },
      {
        key: 'time',
        value: staticTexts["timeText"],
        category: ['requestInfo'],
      },
      {
        key: 'number',
        value: staticTexts['numberText'],
        category: ['requestInfo'],
      },
      {
        key: 'address',
        value: staticTexts['addressText'],
        category: ['requestInfo'],
      },
      {
        key: 'api',
        value: staticTexts['apiText'],
        category: ['requestInfo'],
      },

      {
        key: 'image',
        value: staticTexts['imageText'],
        category: ['sendInfo'],
      },
      {
        key: 'contact',
        value: staticTexts['webLinkText'],
        category: ['sendInfo'],
      },
      {
        key: 'video',
        value: staticTexts['videoText'],
        category: ['sendInfo'],
      },
      {
        key: 'document',
        value: staticTexts['documentText'],
        category: ['sendInfo'],
      },
      {
        key: 'AI',
        value: staticTexts['chatGPT'],
        category: ['action'],
      },
      {
        key: 'redirect',
        value: staticTexts['redirectText'],
        category: ['action'],
      },
      {
        key: 'condition',
        value : 'Conditions',
        category : ['action']
      }
    ],
    filtered: [],
    frequentUsed: [],
    requestInfo: [],
    sendInfo: [],
    action: [],
  });
  const [questions, setQuestions] = useState([]);
  const [quickActions, setQuickActions] = useState(undefined);
  const selectedQuestion = useSelector((state) => state.activeQuestion);
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestions(flow.questions || []);
  }, [flow, selectedQuestion]);

  const setActiveQuestion = (question) => {
    dispatch({ type: LOAD_ACTIVE_QUESTION, payload: { data: {} } });
    setTimeout(() => {
      dispatch({ type: LOAD_ACTIVE_QUESTION, payload: question });
    }, 200);
  };

  useEffect(() => {
    const matches = chatComponents.list?.filter((match) =>
      filterComponentByBot(match.key)
    );

    setChatComponents({
      ...chatComponents,
      filtered: chatComponents.list,
      frequentUsed: matches.filter((ele) =>
        ele.category.includes('frequentUsed')
      ),
      requestInfo: matches.filter((ele) =>
        ele.category.includes('requestInfo')
      ),
      sendInfo: matches.filter((ele) => ele.category.includes('sendInfo')),
      action: matches.filter((ele) => ele.category.includes('action')),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newQuestionAdded.id) {
      const question = flow.questions.find(
        (ele) => ele.id === newQuestionAdded.id
      );
      setActiveQuestion(question);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuestionAdded]);

  const onSearch = (e) => {
    const regex = new RegExp(e.target.value, 'i');
    let matches = chatComponents.list.filter((component) =>
      regex.test(component.value)
    );
    matches = matches.filter((match) => filterComponentByBot(match.key));

    setChatComponents({
      ...chatComponents,
      filtered: matches,
      frequentUsed:
        (matches.filter((ele) => ele.category.includes('requestInfo')).length ||
          matches.filter((ele) => ele.category.includes('sendInfo')).length ||
          matches.filter((ele) => ele.category.includes('action')).length) &&
        e.target.value.length
          ? []
          : matches.filter((ele) => ele.category.includes('frequentUsed')),
      requestInfo: matches.filter((ele) =>
        ele.category.includes('requestInfo')
      ),
      sendInfo: matches.filter((ele) => ele.category.includes('sendInfo')),
      action: matches.filter((ele) => ele.category.includes('action')),
    });
  };

  const resetDropdownOnLeave = () => {
    const [...doc] = document.getElementsByClassName('dropdown-menu');
    doc.forEach((element) => {
      if (
        element.classList.contains('show') &&
        !element.classList.contains('fixed')
      ) {
        element.click();
      }
    });
  };

  const resetSearch = (id) => {
    const search = window.document.getElementById(id);
    search.value = '';

    const matches = chatComponents.list?.filter((match) =>
      filterComponentByBot(match.key)
    );
    setChatComponents({
      ...chatComponents,
      filtered: chatComponents.list,
      frequentUsed: matches.filter((ele) =>
        ele.category.includes('frequentUsed')
      ),
      requestInfo: matches.filter((ele) =>
        ele.category.includes('requestInfo')
      ),
      sendInfo: matches.filter((ele) => ele.category.includes('sendInfo')),
      action: matches.filter((ele) => ele.category.includes('action')),
    });

    const [...scrollBox] = document.getElementsByClassName('scroll-box');
    scrollBox.forEach((scroll) => {
      scroll?.scroll({
        top: 0,
      });
    });
  };

  const getQuestions = () => {
    const data = questions.map((question, index) => {
      const hasUserReply = ![
        'statement',
        'image',
        'contact',
        'video',
        'live_chat',
        'document',
      ].includes(question.type);

      return (
        <Draggable
          key={index.toString()}
          draggableId={String(index)}
          index={index}
        >
          {(provided, snapshot) => {
            const style = {
              boxShadow: snapshot.isDragging
                ? 'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset'
                : '',
              borderRadius: snapshot.isDragging ? 10 : '',
              paddingLeft: snapshot.isDragging ? 10 : '',
            };
            return (
              <div
                className="cursor-pointer chat-list"
                onMouseEnter={() => setQuickActions(question.id)}
                onMouseLeave={() => {
                  setQuickActions(undefined);
                  resetDropdownOnLeave();
                }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {loaderNonBlock.isLoading &&
                loaderNonBlock.index === index &&
                loaderNonBlock.loaderPosition === 'top' ? (
                  <Box
                    display="flex"
                    marginBottom="10px"
                    justifyContent="center"
                  >
                    <CircularProgress size="1.3rem" />
                  </Box>
                ) : (
                  <Divider
                    className={`custom-divider ${
                      quickActions === question.id && 'divider-zoom'
                    } custom-dropdown chat-divider-bubble`}
                  >
                    <div
                      className="remove-dropdown-icon dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => resetSearch(`input-${question.id}`)}
                    >
                      <img
                        width="20px"
                        alt="Plus"
                        src={Plus}
                      />
                    </div>
                    <ul className="dropdown-menu cursor-default p-2">
                      <li>
                        <input
                          id={`input-${question.id}`}
                          className="w-100"
                          placeholder={staticTexts['searchByComponentName']}
                          onChange={onSearch}
                        />
                      </li>
                      <div className="scroll-box">
                        {chatComponents.requestInfo.length ? (
                          <div className="add-btn-section-head">
                            {staticTexts['requestInformation']}
                          </div>
                        ) : null}
                        {chatComponents.requestInfo.map((component) => {
                          return (
                            <ChatComponent
                              loaderPosition="top"
                              key={component.key}
                              componentIndex={index}
                              componentKey={component.key}
                              value={component.value}
                            />
                          );
                        })}
                        {chatComponents.sendInfo.length ? (
                          <div className="add-btn-section-head">
                            {staticTexts['sendInformation']}
                          </div>
                        ) : null}
                        {chatComponents.sendInfo.map((component) => {
                          return (
                            <ChatComponent
                              loaderPosition="top"
                              key={component.key}
                              componentIndex={index}
                              componentKey={component.key}
                              value={component.value}
                            />
                          );
                        })}
                        {chatComponents.action.length ? (
                          <div className="add-btn-section-head text-white">
                            {staticTexts['triggerActions']}
                          </div>
                        ) : null}
                        {chatComponents.action.map((component) => {
                          return (
                            <ChatComponent
                              loaderPosition="top"
                              key={component.key}
                              componentIndex={index}
                              componentKey={component.key}
                              value={component.value}
                            />
                          );
                        })}
                        {!chatComponents.frequentUsed.length &&
                          !chatComponents.requestInfo.length &&
                          !chatComponents.sendInfo.length &&
                          !chatComponents.action.length && (
                            <Box
                              marginTop="140px"
                              flexDirection="column"
                              className="d-flex align-items-center justify-content-center"
                            >
                              <Typography variant="body2">
                                {staticTexts['noComponent']}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  whiteSpace: 'break-spaces',
                                  textAlign: 'center',
                                }}
                              >
                                {staticTexts['addListedComponent']}
                              </Typography>
                            </Box>
                          )}
                      </div>
                    </ul>
                  </Divider>
                )}
                {
                  <div
                    style={style}
                    className={`
                ${
                  loaderNonBlock.isLoading &&
                  loaderNonBlock.index === index &&
                  'component-loading'
                } 
                d-flex flex-column chat-bubble 
                ${quickActions === question.id && 'chat-bubble-hover'} 
                 ${selectedQuestion?.id === question.id && 'chat-bubble-active'}
                 `}
                    onClick={(e) => setActiveQuestion(question)}
                    key={question.id}
                    id={question.id}
                  >
                    <ChatBubble
                      key={index}
                      index={index}
                      id={question.id}
                      question={question}
                      moveCard={moveCard}
                      type={question.type}
                      text={question.labels}
                      hasUserReply={hasUserReply}
                      copyQuestion={copyQuestion}
                      saveDropResult={saveDropResult}
                      setActiveQuestion={setActiveQuestion}
                      isSelected={selectedQuestion?.id === question.id}
                      quickActions={quickActions}
                    />
                  </div>
                }
                {loaderNonBlock.isLoading &&
                loaderNonBlock.index === index &&
                loaderNonBlock.loaderPosition === 'bottom' ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress size="1.3rem" />
                  </Box>
                ) : (
                  quickActions === question.id && (
                    <Divider className="custom-dropdown chat-divider-bubble">
                      <div
                        className="remove-dropdown-icon dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => resetSearch(`input-${question.id}`)}
                      >
                        <img
                          width="20px"
                          alt="Plus"
                          src={Plus}
                        />
                      </div>
                      <ul className="dropdown-menu cursor-default p-2">
                        <li>
                          <input
                            id={`input-${question.id}`}
                            className="w-100"
                            placeholder={staticTexts['searchByComponentName']}
                            onChange={onSearch}
                          />
                        </li>
                        <div className="scroll-box">
                          {chatComponents.requestInfo.length ? (
                            <div className="add-btn-section-head text-white">
                              {staticTexts['requestInformation']}
                            </div>
                          ) : null}
                          {chatComponents.requestInfo.map((component) => {
                            return (
                              <ChatComponent
                                loaderPosition="bottom"
                                key={component.key}
                                componentIndex={index}
                                componentKey={component.key}
                                value={component.value}
                              />
                            );
                          })}
                          {chatComponents.sendInfo.length ? (
                            <div className="add-btn-section-head text-white">
                              {staticTexts['sendInformation']}
                            </div>
                          ) : null}
                          {chatComponents.sendInfo.map((component) => {
                            return (
                              <ChatComponent
                                loaderPosition="bottom"
                                key={component.key}
                                componentIndex={index}
                                componentKey={component.key}
                                value={component.value}
                              />
                            );
                          })}
                          {chatComponents.action.length ? (
                            <div className="add-btn-section-head text-white">
                              {staticTexts['triggerActions']}
                            </div>
                          ) : null}
                          {chatComponents.action.map((component) => {
                            return (
                              <ChatComponent
                                loaderPosition="bottom"
                                key={component.key}
                                componentIndex={index}
                                componentKey={component.key}
                                value={component.value}
                              />
                            );
                          })}
                          {!chatComponents.frequentUsed.length &&
                            !chatComponents.requestInfo.length &&
                            !chatComponents.sendInfo.length &&
                            !chatComponents.action.length && (
                              <Box
                                marginTop="140px"
                                flexDirection="column"
                                className="d-flex align-items-center justify-content-center"
                              >
                                <Typography variant="body2">
                                  {staticTexts['noComponent']}
                                </Typography>
                                <Typography
                                  sx={{
                                    whiteSpace: 'break-spaces',
                                    textAlign: 'center',
                                  }}
                                  variant="caption"
                                >
                                  {staticTexts['addListedComponent']}
                                </Typography>
                              </Box>
                            )}
                        </div>
                      </ul>
                    </Divider>
                  )
                )}
              </div>
            );
          }}
        </Draggable>
      );
    });

    return data;
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    // Reshuffle questions
    setQuestions((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const changeQuestionPosition = (dragIndex, dropIndex) => {
    const payload = [...questions];
    const question = payload[dragIndex];
    payload.splice(dragIndex, 1);
    payload.splice(dropIndex, 0, question);

    setQuestions(payload);
    updateBot({ questionData: question, action: 'drag', dragIndex, dropIndex })
      .then((response) => {
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDropResult = useCallback(() => {
    updateBot({ ...flow, questions })
      .then((response) => {
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const copyQuestion = (question) => {
    function findQuestion(item) {
      return item.id === selectedQuestion.id;
    }
    let indexOfQuestion = flow.questions.length ? flow.questions.findIndex(findQuestion) : 0;
    const questionPayload = [...flow.questions];
    const newQuestion = {
      ...question,
      _id: undefined,
      id: uuid(),
      next: { target: '', type: '' },
    };
    questionPayload.splice(indexOfQuestion + 1, 0, newQuestion);

    dispatch({
      type: LOAD_FLOW,
      payload: {
        ...flow,
        questions: questionPayload,
      },
    });

    dispatch({
      type: UPDATE_NON_BLOCK_LOADER,
      payload: {
        isLoading: true,
        index: indexOfQuestion + 1,
        loaderPosition: 'top',
      },
    });

    setTimeout(() => {
      showComponentAnimation(newQuestion.id);
    }, 100);

    updateBot({
      index: indexOfQuestion,
      questionData: newQuestion,
      action: 'copy',
    })
      .then((response) => {
        dispatch({ type: LOAD_NEW_QUESTION, payload: { id: newQuestion.id } });
        dispatch({
          type: UPDATE_NON_BLOCK_LOADER,
          payload: { isLoading: false, index: 0, loaderPosition: 'top' },
        });
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Question copied successfully',
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'error',
            description:
              error.message || 'Could not copy, Please try again later...',
          },
        });
      })
      .finally(() =>
        dispatch({
          type: UPDATE_NON_BLOCK_LOADER,
          payload: { isLoading: false, index: 0, loaderPosition: 'top' },
        })
      );
  };

  return (
    <>
      <div className="title-v1 sticky-header">{staticTexts['createOrReorderChatFlow']}</div>

      <div
        id="flow-dot-bg"
        style={{ marginTop: '52px', padding: '0 28px' }}
        className="scroll-content"
      >
        <DragDropContext
          onDragEnd={(e) => {
            changeQuestionPosition(e.source.index, e.destination.index);
          }}
        >
          <Droppable droppableId={'table-drop'}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div
                  style={{
                    paddingBottom: snapshot.isDraggingOver ? '200px' : '',
                  }}
                >
                  {getQuestions()}
                </div>

                <div
                  className="custom-dropdown"
                  style={{ display: snapshot.isDraggingOver ? 'none' : '' }}
                >
                  <div
                    className="last-ele cursor-pointer dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => resetSearch('last-dropdown-input')}
                  >
                    {staticTexts['addChatComponent']}
                  </div>
                  <ul className="dropdown-menu text-white fixed p-2">
                    <li>
                      <input
                        id="last-dropdown-input"
                        className="w-100"
                        placeholder={staticTexts['searchByComponentName']}
                        onChange={onSearch}
                      />
                    </li>
                    <div className="scroll-box">
                      {chatComponents.requestInfo.length ? (
                        <div className="add-btn-section-head text-white">
                          {staticTexts['requestInformation']}
                        </div>
                      ) : null}
                      {chatComponents.requestInfo.map((component) => {
                        return (
                          <ChatComponent
                            loaderPosition="top"
                            key={component.key}
                            componentIndex={questions.length}
                            componentKey={component.key}
                            value={component.value}
                          />
                        );
                      })}
                      {chatComponents.sendInfo.length ? (
                        <div className="add-btn-section-head text-white">
                          {staticTexts['sendInformation']}
                        </div>
                      ) : null}
                      {chatComponents.sendInfo.map((component) => {
                        return (
                          <ChatComponent
                            loaderPosition="top"
                            key={component.key}
                            componentIndex={questions.length}
                            componentKey={component.key}
                            value={component.value}
                          />
                        );
                      })}
                      {chatComponents.action.length ? (
                        <div className="add-btn-section-head text-white">
                          {staticTexts['triggerActions']}
                        </div>
                      ) : null}
                      {chatComponents.action.map((component) => {
                        return (
                          <ChatComponent
                            loaderPosition="top"
                            key={component.key}
                            componentIndex={questions.length}
                            componentKey={component.key}
                            value={component.value}
                          />
                        );
                      })}
                      {!chatComponents.frequentUsed.length &&
                        !chatComponents.requestInfo.length &&
                        !chatComponents.sendInfo.length &&
                        !chatComponents.action.length && (
                          <Box
                            marginTop="140px"
                            flexDirection="column"
                            className="d-flex align-items-center justify-content-center"
                          >
                            <Typography variant="body2">
                              {staticTexts['noComponent']}
                            </Typography>
                            <Typography
                              sx={{
                                whiteSpace: 'break-spaces',
                                textAlign: 'center',
                              }}
                              variant="caption"
                            >
                              {staticTexts['addListedComponent']}
                            </Typography>
                          </Box>
                        )}
                    </div>
                  </ul>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}
