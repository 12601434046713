import { isArray } from "lodash";
import { americanFootballPlayer, americanFootballTeam, bankSelection, basketballPlayer, basketballTeam, calendar, car, clock, creditCard, currency, flight, globe, googleMapKeyboard, hamburgerIcon, hotel, hotelStars, languageKeyboard, text, textIcon, timeZone, timezone, trophy, store as storeIcon, transporter, cricketLeague, cricketPlayer, cricketTeam, rugbyPlayer } from "../assets/icons";
import { getErrorTexts } from "../assets/json/errorTexts";
import store from "../redux/store";
export const filterComponentByBot = (type) => {
  return ['statement', 'question', 'name', 'email', 'phone', 'button', 'image', 'date', 'time', 'number', 'location', 'video', 'api', 'file', 'AI', 'contact', 'redirect', 'document', 'condition'].includes(type)
}

export const showComponentAnimation = (questionId) => {
  const questionElement = window.document.getElementById(questionId);
  if (questionElement) {
    questionElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    questionElement.style.width = "0%";
    questionElement.style.overflow = "hidden";
    setTimeout(() => {
      questionElement.style.width = "100%";
    }, [1]);
    setTimeout(() => {
      questionElement.style.overflow = "inherit";

    }, [1000])
  }
}


export const getTextByLanguage = (lang, data) => {
  console.log(lang,data)
  if (!Array.isArray(data)) {
    return null; // or handle the non-array case according to your requirements
  }
  const languageEntry = data.find((item) => item.lang === lang);

  if (!languageEntry) {
    return null; // or handle the case when the language is not found
  }
  return languageEntry?.label || languageEntry?.value || languageEntry?.text || languageEntry?.error || "";
};


export const createErrorMessage = (language, message, errorTextType) => {

  const messageArray = [
    { lang: 'ar', error: '' },
    { lang: 'en', error: '' },
    { lang: 'zh', error: '' },
    { lang: 'hi', error: '' },
    { lang: 'ur', error: '' },
    { lang: 'fr', error: '' },
    { lang: 'pt', error: '' },
    { lang: 'es', error: '' },
    { lang: 'de', error: '' },
    { lang: 'ru', error: '' },
  ];

  const updatedMessageArray = messageArray.map((item) => {
    if (item.lang === language) {
      item.error = message.error ?? message;
    }
    else {
      item.error = getErrorTexts(item.lang)?.[errorTextType]
    }
    return item
  })

  return updatedMessageArray;
};


export const createOptions = (language, array2) => {
  const array1 = store.getState().activeQuestion.options;
  // Helper function to find the index of the language in the "values" array
  function findIndexByLanguage(valuesArray, lang) {
    return valuesArray?.findIndex(value => value.lang === lang);
  }
  // Create a copy of array1 to avoid mutating the original state
  const newArray1 = [...array1].filter((item) => array2.some(obj2 => item.id === obj2.id)
  );
  // Update values in the new array
  newArray1.forEach((obj, index) => {
    const valueIndex = findIndexByLanguage(obj.values, language);
    if (valueIndex !== -1) {
      // Create a copy of the object and the "values" array to avoid mutation
      const updatedObject = {
        ...obj,
        values: [...obj.values]
      };

      const value = array2.find(item => item.id === obj.id)?.value
      // Update the value for the specified language
      if (value !== undefined) {
        updatedObject.values[valueIndex] = {
          ...updatedObject.values[valueIndex],
          value
        };
      }
      // Update the object in the new array
      newArray1[index] = updatedObject;
    }
  });
  // Return the updated array1
  return newArray1;
};

export const createCases = (language, array2) => {
  const array1 = store.getState().activeQuestion?.condition?.cases;
  // Helper function to find the index of the language in the "values" array
  function findIndexByLanguage(valuesArray, lang) {
    return valuesArray?.findIndex(value => value.lang === lang);
  }
  // Create a copy of array1 to avoid mutating the original state
  const newArray1 = [...array1].filter((item) => array2.some(obj2 => item.id === obj2.id)
  );

  // Update values in the new array
  newArray1.forEach((obj, index) => {
    const valueIndex = findIndexByLanguage(obj.values, language);
    if (valueIndex !== -1) {
      // Create a copy of the object and the "values" array to avoid mutation
      const updatedObject = {
        ...obj,
        values: [...obj.values]
      };

      const value = array2.find(item => item.id === obj.id)?.value
      // Update the value for the specified language
      if (value !== undefined) {
        updatedObject.values[valueIndex] = {
          ...updatedObject.values[valueIndex],
          value
        };
      }
      // Update the object in the new array
      newArray1[index] = updatedObject;
    }
  });
  // Return the updated array1
  return newArray1;
};
//TODO: add icons
export const keyboardTypes = [
  { label: 'Date', icon: 'calendar' },
  { label: 'Time', icon: 'clock' },
  { label: 'Hotel star', icon: 'hotel-star' },
  { label: 'Bank selection', icon: 'bank-selection' },
  { label: 'Car size', icon: 'car' },
  { label: 'Text', icon: 'text' },
  { label: 'Timezone', icon: 'timezone' },
  { label: 'Credit card expiry', icon: 'credit-card' },
  { label: 'City', icon: 'city' },
  { label: 'Country', icon: 'globe' },
  { label: 'Language', icon: 'language' },
  { label: 'Location', icon: 'maps' },
  { label: 'Hamburger', icon: 'hamburger' },
  { label: 'American football team', icon: 'american-football-team' },
  { label: 'American football player', icon: 'american-football-player' },
  { label: 'Basketball Player', icon: 'basketball-player' },
  { label: 'Basketball Team', icon: 'basketball-team' },
  { label: 'Flight', icon: 'flight' },
  { label: 'Hotel', icon: 'hotel' },
  { label: 'Hotel type', icon: 'hotel' },
  { label: "Currency", icon: 'currency' },
  { label: "Leagues", icon: "leagues" },
  { label: "Car type", icon: "car" },
  { label: "Store", icon: "store" },
  { label: "Transporter", icon: "transporter" },
  { label: "Cricket league", icon: "cricket-league" },
  { label: "Cricket player", icon: "cricket-player" },
  { label: "Cricket team", icon: "cricket-team" },
  { label: "Rugby player", icon: "rugby-player" },
]

export const getIcons = (iconType) => {
  switch (iconType) {
    case "text":
      return text;
    case "clock":
      return clock;
    case "hotel-star":
      return hotelStars;
    case "bank-selection":
      return bankSelection;
    case "car":
      return car;
    case "calendar":
      return calendar;
    case "credit-card":
      return creditCard;
    case "flight":
      return flight;
    case "globe":
      return globe;
    case "language":
      return languageKeyboard;
    case "maps":
      return googleMapKeyboard;
    case "hamburger":
      return hamburgerIcon;
    case "hotel":
      return hotel;
    case "hotel-type":
      return hotel;
    case "american-football-team":
      return americanFootballTeam;
    case "american-football-player":
      return americanFootballPlayer;
    case "basketball-player":
      return basketballPlayer;
    case "basketball-team":
      return basketballTeam;
    case "timezone":
      return timezone;
    case "currency":
      return currency;
    case "leagues":
      return trophy;
    case "store":
      return storeIcon;
    case "transporter":
      return transporter;
    case "cricket-league":
      return cricketLeague;
    case "cricket-player":
      return cricketPlayer;
    case "cricket-team":
      return cricketTeam;
    case "rugby-player":
      return rugbyPlayer
    default:
      return text;
  }
}

export const getOptionValue = (lang, currentIndex) => {
  // Increment the index
  const newIndex = currentIndex + 1;

  // Define language-specific options
  const options = {
    en: 'Option',
    hi: 'विकल्प',
    zh: '选项',
    ar: 'الخيار',
    ur: 'اختیار',
    pt: 'Opção',
    es: 'Opción',
    fr: 'Option',
    de: 'Option',
    ru: 'Вариант'
    // Add more languages as needed
  };

  // Get the option text for the specified language
  const optionText = options[lang] || options.en;

  // Construct and return the result
  return `${optionText} ${newIndex}`;
}


export const getNewOptions = (index) => {
  return [
    {
      lang: 'en',
      value: getOptionValue('en', index),
    },
    {
      lang: 'hi',
      value: getOptionValue('hi', index),
    },
    {
      lang: 'zh',
      value: getOptionValue('zh', index), // Chinese
    },
    {
      lang: 'fr',
      value: getOptionValue('fr', index), // French
    },
    {
      lang: 'ru',
      value: getOptionValue('ru', index), // Russian
    },
    {
      lang: 'pt',
      value: getOptionValue('pt', index), // Portuguese
    },
    {
      lang: 'ar',
      value: getOptionValue('ar', index), // Arabic
    },
    {
      lang: 'ur',
      value: getOptionValue('ur', index), // Urdu
    },
    {
      lang: 'es',
      value: getOptionValue('es', index), // Spanish
    },
    {
      lang: 'de',
      value: getOptionValue('de', index), // German
    },
  ];
};

export const getPlaceholderText = () => {
  const translations = [
    { lang: 'en', text: "Select one option" },
    { lang: 'hi', text: "एक विकल्प चुनें" },
    { lang: 'zh', text: "选择一个选项" },
    { lang: 'ar', text: "اختر خيارًا واحدًا" },
    { lang: 'ur', text: "ایک اختیار منتخب کریں" },
    { lang: 'pt', text: "Selecionar uma opção" },
    { lang: 'es', text: "Seleccionar una opción" },
    { lang: 'fr', text: "Sélectionnez une option" },
    { lang: 'de', text: "Eine Option auswählen" },
    { lang: 'ru', text: "Выберите один вариант" }

  ];

  return translations;
};

export const updatePlaceholderText = (lang, placeholders, newValue) => {
  const index = placeholders.findIndex(entry => entry.lang === lang);

  if (index !== -1) {
    const updatedPlaceholders = [...placeholders];
    updatedPlaceholders[index] = { ...updatedPlaceholders[index], text: newValue };

    return updatedPlaceholders;
  }

  return placeholders;
};

export const createUniqueId = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const uuidLowerCase = uuidv4().toLowerCase();
  return `${timestamp}-${uuidLowerCase}`;
}

// Function to generate a UUID (version 4)
const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export const getBotOptions = () => {
  const bots = store.getState().allBots;
  const options = bots.map((bot) => (
    {
      label: bot.name,
      _id: bot._id
    }
  ))
  return options
}

const adamKeys = [
  { label: 'Adam Customer Rating', key: 'customerRating' },
  { label: 'Adam Shipment Updates', key: 'adamShipmentUpdates' },
  { label: "User category", key: "category" },
  { label: "Senders Phone Number", key: "senderPhoneNumber" },
  { label: "Receiver Phone Number", key: "receiverPhoneNumber" },
  { label: "Customer Name", key: "customerName" },
  { label: "Receiver’s Name", key: "receiverName" },
  { label: "Selected Language", key: "language" },
  { label: "Pick Up location", key: "pickupLocation" },
  { label: "Document/Non-document", key: "isDocument" },
  { label: "Drop off location", key: "dropoffLocation" },
  { label: "Pick up Kardi Box Number", key: "pickUpKardiBoxNumber" },
  { label: "Drop Off Kardi Box Number", key: "dropOffKardiBoxNumber" },
  { label: "Shipment Weight", key: "weight" },
  { label: "Shipment Description", key: "description" },
  { label: "Car Size", key: "carSize" },
  { label: "Additional Service", key: "additionalService" },
  { label: "3PL", key: "3plPartner" },
  { label: "Stripe Payment Link", key: "stripePaymentLink" },
  { label: "Shipment Label", key: "shipmentLabel" },
  { label: 'Reference Number', key: "referenceNumber" },
  { label: 'Pick Up Date', key: "pickUpDate" },
  { label: 'Pick Up Time', key: "pickUpTime" },
  { label: 'Selection Type', key: "selectionType" },
  { label: 'Receive Shipment Updates', key: 'receiveShipmentUpdates' },
  { label: "Issue Type", key: 'issueType' },
  { label: 'Rider Country', key: 'riderCountry' },
  { label: 'Rider City', key: 'riderCity' },
  { label: 'Car Type', key: 'carType' },
  { label: 'Rider Timings', key: 'riderTimings' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Payment Account Number', key: 'paymentAccountNumber' },
  { label: 'Swift/Bic code', key: 'swiftBicCode' },
  { label: 'Store Name', key: 'storeName' },
  { label: 'Store Location', key: 'storeLocation' },
  { label: 'Sarah Action Type', key: 'actionType' },
  { label: 'Sarah Country', key: 'country' },
  { label: 'Sarah City', key: 'city' },
  { label: 'Sarah Departure Date', key: 'departureDate' },
  { label: 'Sarah Departure Time', key: 'departureTime' },
  { label: 'Sarah Return Date', key: 'returnDate' },
  { label: 'Sarah Return Time', key: 'returnTime' },
  { label: 'Sarah Airport Pickup', key: 'airportPickup' },
  { label: 'Sarah Car Type', key: 'carType' },
  { label: 'Sarah Car Select', key: 'selectedCar' },
  { label: 'Sarah Report Comment', key: 'sarahReportComment' },
  { label: 'Sarah Issue Type', key: 'sarahIssueType' },
  { label: 'Sarah  Issue Description', key: 'sarahIssueDescription' },
  { label: 'Sarah Booking Id', key: 'sarahBookingId' },
  { label: 'Sarah Hotel City', key: 'sarahHotelCity' },
  { label: 'Sarah Hotel Name', key: 'sarahHotelName' },
  { label: 'Sarah Hotel Type', key: 'sarahHotelType' },
  { label: 'Sarah Hotel Stars', key: 'sarahHotelStars' },
  { label: 'Sarah Hotel Room Type', key: 'sarahHotelRoomType' },
  { label: 'Sarah Hotel Room Count', key: 'sarahHotelRoomCount' },
  { label: 'Sarah Hotel Check In Date', key: 'sarahHotelCheckInDate' },
  { label: 'Sarah Hotel Check Out Date', key: 'sarahHotelCheckOutDate' },
  { label: 'Sarah Hotel Guest Count', key: 'sarahHotelGuestCount' },
  { label: 'Sarah Hotel Best Rates', key: 'sarahHotelBestRates' },
  { label: 'Sarah Hotel Budget Range', key: 'sarahHotelBudgetRange'},
  { label: 'Select sport', key: 'sport' },
  { label: 'Select League', key: 'league' },
  { label: 'Select Team', key: 'team' },
  { label: 'Nora Issue Type', key: 'noraIssueType' },
  { label: 'Nora  Issue Description', key: 'noraIssueDescription' },
  { label: 'Nora Booking Id', key: 'noraBookingId' }
];

const sarahKeys = [
  { label: 'Sarah Action Type', key: 'actionType' },
  { label: 'Sarah Country', key: 'country' },
  { label: 'Sarah City', key: 'city' },
  { label: 'Sarah Departure Date', key: 'departureDate' },
  { label: 'Sarah Departure Time', key: 'departureTime' },
  { label: 'Sarah Return Date', key: 'returnDate' },
  { label: 'Sarah Return Time', key: 'returnTime' },
  { label: 'Sarah Airport Pickup', key: 'airportPickup' },
  { label: 'Sarah Car Type', key: 'carType' },
  { label: 'Sarah Car Select', key: 'selectedCar' },
  { label: 'Sarah Report Comment', key: 'sarahReportComment' },
  { label: 'Sarah Issue Type', key: 'sarahIssueType' },
  { label: 'Sarah  Issue Description', key: 'sarahIssueDescription' },
  { label: 'Sarah Booking Id', key: 'sarahBookingId' },
  { label: 'Sarah Hotel City', key: 'sarahHotelCity' },
  { label: 'Sarah Hotel Name', key: 'sarahHotelName' },
  { label: 'Sarah Hotel Type', key: 'sarahHotelType' },
  { label: 'Sarah Hotel Stars', key: 'sarahHotelStars' },
  { label: 'Sarah Hotel Room Type', key: 'sarahHotelRoomType' },
  { label: 'Sarah Hotel Room Count', key: 'sarahHotelRoomCount' },
  { label: 'Sarah Hotel Check In Date', key: 'sarahHotelCheckInDate' },
  { label: 'Sarah Hotel Check Out Date', key: 'sarahHotelCheckOutDate' },
  { label: 'Sarah Hotel Guest Count', key: 'sarahHotelGuestCount' },
  { label: 'Sarah Hotel Best Rates', key: 'sarahHotelBestRates' },
  { label: 'Sarah Hotel Budget Range', key: 'sarahHotelBudgetRange'}
];

const noraKeys = [
  { label: 'Select sport', key: 'sport' },
  { label: 'Select League', key: 'league' },
  { label: 'Select Team', key: 'team' },
  { label: 'Nora Issue Type', key: 'noraIssueType' },
  { label: 'Nora  Issue Description', key: 'noraIssueDescription' },
  { label: 'Nora Booking Id', key: 'noraBookingId' }
];

export const getLabelByKey = (key) => {
  const foundItem = adamKeys.find(item => item.key === key);
  return foundItem ? foundItem.label : null;
}


export const getKeysByBot = (bot) => {
  switch (bot.toLowerCase()) {
    case 'adam':
      return adamKeys;
    case 'sarah':
      return sarahKeys;
    case 'nora':
      return noraKeys;
    default:
      return adamKeys;
  }
}