import React from 'react';
import { buttonLoader } from '../../../assets/icons';
import './button-loader.css'


const ButtonLoader = () => {
  return (
    <div className='button-loader-container'> 
        <img 
        className='button-loader'
        src={buttonLoader} alt="button-loader"
        />
    </div>
  )
}

export default ButtonLoader