import React from 'react';
import './style.css'

const languages = [
    { label: 'English', value: 'en' },
    { label: 'हिंदी', value: 'hi' }, // Hindi
    { label: '中文', value: 'zh' }, // Chinese
    { label: 'Português', value: 'pt' },
    { label: 'Deutsch', value: 'de' }, // German
    { label: 'Français', value: 'fr' }, // French
    { label: 'اردو', value: 'ur' }, // Urdu
    { label: 'العربية', value: 'ar' }, // Arabic
    { label: 'Español', value: 'es' }, // Spanish
    { label: 'Русский', value: 'ru' }, // Russian
  ];
const CustomTab = (props) => {
    const {activeTab,onChange} = props;
    console.log(activeTab,'hereeeee')
  return (
    <div className='main-tab-wrapper'>
        <nav>
        <ul
        className='list-wrapper'>
       {languages.map((lang ,key)=>(
        <li key={key} className='tab-list' onClick={()=>onChange(lang.value)}>
            <span className={lang.value === activeTab ? 'tab-active tab-text' : 'tab-inactive tab-text'}>
                {lang.label}
            </span>
            <span className={lang.value === activeTab ? 'tab-indicator' : 'd-none'}>
            </span>
        </li>
       ))}
      </ul>
        </nav>
      </div>
  )
}

export default CustomTab;