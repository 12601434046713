import './loader.css'
import React from "react";
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  const loaderAction = useSelector(state => state.loader)

  return (
    loaderAction && (
      <div className="loader" >
        <div className="loader-div">
        <CircularProgress color='inherit'/>
        </div>
      </div >
    )
  );
}

export default Loader;