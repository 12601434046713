import { useSelector } from "react-redux";
import { useEffect } from "react";
import {httpRequest} from '../../../../services/network';
export default function Catalogue() {
  const bot = useSelector(state => state.bot);

  useEffect(() => {
    setCatlogVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCatlogVisibility = () => {
    httpRequest(
      'POST',
      `${process.env.REACT_APP_API_META}${bot.accountApiKey}/whatsapp_commerce_settings?is_catalog_visible=true&&access_token=${bot.accessToken}`,
      )
      .catch((error) => console.log(error));
  }
  return (
    <>
    </>
  );
}