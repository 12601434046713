import './document.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_LOADER, UPDATE_TOAST } from '../../../../redux/actions/actions';
import { uploadImage } from '../../../../services/upload';
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useFormContext } from 'react-hook-form';
import {documentFilled as DocumentIcon} from "../../../../assets/icons";
import Badge from '@mui/material/Badge';
import CloseIcon from "../../../../assets/images/close.svg";
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { useSelector } from 'react-redux';
import { Divider, TextField } from '@mui/material';

export default function Document(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState(props.data.source);
  const language = useSelector((state)=> state.language.lang)
  const staticTexts = getStaticText(language);
  const attributes = useSelector(state => state.attributes) || [];
  const [state, setState] = useState({
    slashPressed: false
  })

  const uploadSelectedDocument = (e) => {
    dispatch({ type: UPDATE_LOADER, payload: true })
    const formData = new FormData();
    formData.append('id', props.data.id);
    formData.append('type', 'document');
    formData.append('file', e.target.files[0], e.target.files[0].name);
    const fileName = e.target.files[0].name;

    uploadImage(formData)
      .then((response) => {
        setTimeout(() => {
          setSelectedDocument(fileName);
          methods.setValue('header', fileName);
          methods.setValue('source', response.data);

          dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: response.data, header: fileName } });
          dispatch({ type: UPDATE_LOADER, payload: false });
        }, 500)
      })
      .catch(error => {
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true, type: 'error', description: error.response.status === 0
              ? 'The document has exceeded the size limit of 1 MB, Please compress the file and upload again.' : error.message
          }
        });
        dispatch({ type: UPDATE_LOADER, payload: false });
        console.log(error);
      })
  }

  const showFolder = () => {
    const elem = document.getElementById('upload-input');

    elem.click();
  }

  const resetValue = () => {
    setSelectedDocument('');
    methods.setValue('source', '');
    methods.setValue('header', '');
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: '' } });
  }

  const addVariable = (key) => {
    const text = String(props.data.source)?.substring(0, String(props.data.source).length - 1);
    const index = text.indexOf('/')
    const updatedText = text.substring(0, index) + key + text.substring(index + 1);
    methods.setValue("source", updatedText);
    setState(prev => ({ ...prev, slashPressed: false }));
  }

  return (
    <>
      <div className={`d-flex mb-1`}>
        <input id="upload-input" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" hidden={true} type="file" className="file-replace-input" name="documents" onChange={(e) => uploadSelectedDocument(e)} />
        <div
          style={{
            border: '1px dashed rgb(17, 140, 252)',
            width: '100%',
            textAlign: 'center',
            padding: '15px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            // background: '#EDEFF7',
            cursor:"pointer"
          }}
        >
          {
            selectedDocument ?
              <div className='w-100 text-center badge-border pt-2'>
                <div className='d-flex align-items-center justify-content-center flex-column'>
                  <Badge
                    sx={{ height: "100%", maxWidth: "100%" }}
                    badgeContent={<img className='cursor-pointer' width={22} height={22} src={CloseIcon} alt='CloseIcon' onClick={resetValue} />}
                  >
                    <img width={50} src={DocumentIcon} alt='DocumentIcon' />
                  </Badge>
                  <div className='mt-2'>
                    {(selectedDocument.split('/').pop() || '').substr(-10)}
                  </div>
                </div>
              </div>
              :
              <div className="uploadimag">
                <div onClick={(e) => showFolder()} >
                  <div>
                    <span className="material-icons text-primary">
                      cloud_upload
                    </span>
                  </div>
                  <div>
                    <p><span className="text-primary">{staticTexts['clickHere']}</span> {staticTexts['toUpload']}</p>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
      <Divider className='mb-3'>OR</Divider>
      <div className="custom-input-box input-group position-relative">
        <label>Insert Document URL</label>
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          width:"100%"
        }}
        >
          <TextField
            size="small"
            fullWidth
            className="form-control theme-textarea"
            defaultValue={selectedDocument}
            {...methods.register("source")}
            onChange={(e) => {
              setSelectedDocument(e.target.value)
              methods.setValue("source", e.target.value)
            }}
            onKeyUp={e => {
              if (e.key === "/") {
                setState(prev => ({ ...prev, slashPressed: true }));
              } else {
                setState(prev => ({ ...prev, slashPressed: false }));
              }
            }}
            InputProps={{
              className: "theme-input",
              sx: {
                fontSize: 14,
                padding: 0
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
        </div>
        {
          state.slashPressed ?
            <div
              className="custom-dropdown slash-dropdown">
              <ul style={{ transform: "translate3d(1px, -330px, 0px)", width: "100%", maxHeight: 300 }}>
                <div className="add-btn-section-head">{staticTexts["defaultAttributes"]}</div>

                <div className="attribute-name" onClick={() => addVariable("{{name}}")}>{staticTexts["nameText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{whatsappName}}")}>{staticTexts["whatsappName"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{email}}")}>{staticTexts["emailText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{phone}}")}>{staticTexts["phoneNumberText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{whatsAppNumber}}")}>{staticTexts["whatsappNumber"]}</div>

                {attributes?.length ? <div className="add-btn-section-head">{staticTexts["customAttributes"]}</div> : null}
                {
                  (attributes)?.map((attribute, i) => {
                    return <div key={i} className="attribute-name" onClick={() => addVariable(`{{${attribute.key}}}`)}>{attribute.displayName}</div>
                  })
                }
              </ul>
            </div>
            :
            null
        }
      </div>
    </>
  );
}