import ComponentV1 from '../ComponentsV1';
import FlowQuestions from '../Flow';
import QuestionCustomizationLayout from '../QuestionCustomizationLayout';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_FLOW_LANGUAGE } from '../../../redux/actions/actions';
import CustomTab from '../../Shared/TabPanel';


export default function OldLayout() {
  const [activeTab, setActiveTab] = useState('en');
  const dispatch = useDispatch();
  const handleChange = (newValue) => {
    dispatch({ type: UPDATE_FLOW_LANGUAGE, payload: newValue });
    setActiveTab(newValue);
    localStorage.setItem('flowLanguage', newValue);
  };

  return (
    <>
      <CustomTab activeTab={activeTab} onChange={handleChange} />

      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexDirection: 'row',
          height: '88vh'
        }}
      >
        <div
          className="h-100"
          style={{
            width: '20%',
            position: 'relative',
            background: '#000',
            borderRadius: '16px',
          }}
        >
          <ComponentV1 />
        </div>
        <div
          className="h-100"
          style={{
            width: '45%',
            position: 'relative',
            background: '#000',
            borderRadius: '16px',
            overflow: 'auto'
          }}
        >
          <FlowQuestions />
        </div>
        <div
          className="h-100"
          style={{
            width: '35%',
            position: 'relative',
            background: '#000',
            color: '#fff',
            borderRadius: '16px',
            overflow:'auto'
          }}
        >
          <QuestionCustomizationLayout />
        </div>
      </div>
    </>
  );
}
