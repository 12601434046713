import { v4 as uuid } from 'uuid';
import { getFlowTexts } from '../assets/json/flowTexts';
import { createUniqueId } from './helper';
import store from '../redux/store';

export const createQuestion = (type, label,language) => {
  return {
    labels: createLabel(language,label,type),
    secondaryLabel: '',
    icon: '',
    example: '',
    required: false,
    skip: false,
    back: false,
    delay: 1,
    message: [
      { 
        lang : language,
        error: '',
        success: '',
        unavailable: '',
      },
    ],
    validation: false,
    next: {
      type: 'question',
      target: '',
    },
    _id: '5f33e8a0dc169947560501ab',
    id: createUniqueId(),
    type,
    name: type,
    options: [],
    links: [],
    country: {
      dial_code: '91',
      name: 'India',
      code: 'IN',
    },
    subType : {
      keyboardType :'',
      icon :'',
      placeholderTexts : [{
        lang : language,
        text : ''
      }]
    },
    tag : {
      _bot : '',
      botName : '',
      key : ''
    }
  };
};

export const createLabel = (lang, label,type) => {
  const labels = [
    {
      lang: 'en',
      label: '',
    },
    {
      lang: 'hi',
      label: '',
    },
    {
      lang: 'zh',
      label:'',
    },
    {
      lang: 'pt',
      label:'',
    },
    {
      lang: 'de',
      label:'',
    },
    {
      lang: 'fr',
      label:'',
    },
    {
      lang: 'ur',
      label:'',
    },
    {
      lang: 'ar',
       label:'',
    },
    {
      lang: 'es',
      label:'',
    },
    {
      lang: 'ru',
      label:'',
    },
  ];

  // Use forEach to modify the array in place
  labels.forEach((item) => {
    if (item.lang === lang) {
      item.label = label;
    }
    else{
      item.label = getFlowTexts(item.lang)?.[type] || ""
    }
  });

  return labels;
};

export const updateLabel = (lang,label) => {
  const labels = store.getState().activeQuestion.labels;
  const updatedLabel = labels.map((item) => {
    if (item.lang === lang) {
      return {
        ...item,
        label: label
      };
    }
    return item;
  });
  return updatedLabel
}

export const createTitles = () => {
  const titles = [
    {
      lang: 'en',
      value: 'Open link',
    },
    {
      lang: 'hi',
      value: 'लिंक खोलें',
    },
    {
      lang: 'zh',
      value: '打开链接',
    },
    {
      lang: 'pt',
      value: 'Abrir link',
    },
    {
      lang: 'de',
      value: 'Link öffnen',
    },
    {
      lang: 'fr',
      value: 'Ouvrir le lien',
    },
    {
      lang: 'ur',
      value: 'لنک کھولیں',
    },
    {
      lang: 'ar',
      value: 'افتح الرابط',
    },
    {
      lang: 'es',
      value: 'Abrir enlace',
    },
    {
      lang: 'ru',
      value: 'Открыть ссылку',
    }
  ];

  return titles;
};


export const updateLinks = (language,link ) => {
  const links = store.getState().activeQuestion.links;
 // Helper function to find the index of the language in the "titles" array
 function findIndexByLanguage(valuesArray, lang) {
  return valuesArray?.findIndex(value => value.lang === lang);
}
 // Create a copy of array1 to avoid mutating the original state
 const linksCopy = [...links].filter((item) => link.some(obj2 => item.id === obj2.id)
 );
  // Update values in the new array
  linksCopy.forEach((obj, index) => {
    const valueIndex = findIndexByLanguage(obj.titles, language);
    if (valueIndex !== -1) {
      // Create a copy of the object and the "titles" array to avoid mutation
      const updatedObject = {
        ...obj,
        titles: [...obj.titles],
        type : link.find((item)=> item.id === obj.id)?.type,
        value : link.find((item)=> item.id === obj.id)?.value
      };
      console.log('updatedObject',updatedObject)
      const title = link.find(item => item.id === obj.id)?.title
      // Update the value for the specified language
      if (title !== undefined) {
        updatedObject.titles[valueIndex] = {
          ...updatedObject.titles[valueIndex],
          value : title
        };
      }
  // Update the object in the new array
  linksCopy[index] = updatedObject;
}
});
console.log('linksCopy',linksCopy)
return linksCopy;
};
