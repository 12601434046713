import React, { useEffect, useState } from 'react';
import { v4 as uuid } from "uuid";
import { Select, TextField, FormControl, InputLabel, MenuItem, Button, Tooltip } from "@mui/material";
// import Plus from "../../../../assets/images/plus.svg";
// import Minus from "../../../../assets/images/remove_circle_black.svg";
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { UPDATE_ACTIVE_QUESTION, UPDATE_TOAST } from '../../../../redux/actions/actions';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext } from "react-hook-form";
import { BotMethods } from '../../../../services/bot';
import { useSelector, useDispatch } from 'react-redux';
import { plusIcon as Plus, minusIcon as Minus } from '../../../../assets/icons';
import { getTextByLanguage } from '../../../../utilities/helper';


export default function ApiComponent({ questionData }) {
  const language = useSelector((state)=> state.language.lang)
  const staticTexts = getStaticText(language);
  const dispatch = useDispatch();
  const { getApis } = BotMethods();
  const { register, setValue, getValues } = useFormContext();
  const questions = useSelector(state => state.flow.questions.filter(item => item.id !== questionData.id));
  const [state, setState] = useState({
    apiList: []
  });

  const fetchData = async () => {
    try {
      const apiList = await getApis();
      setState(prev => ({ ...prev, apiList }));
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteOption = (option) => {
    let data = questionData.options.filter(data => data.id !== option.id);
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    setValue('options', data);
  }

  const addOption = () => {
    if (questionData.options.length >= 10) {
      return dispatch({
        type: UPDATE_TOAST,
        payload: { show: true, type: 'error', description: 'Whatsapp only allows max 10 options.' }
      });

    }

    let option = {
      id: uuid(),
      type: 'api',
      value: `200`,
      next: {
        type: '',
        target: ''
      }
    };
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: [...questionData.options, { ...option }] } });
    setValue('options', [...getValues().options, { ...option }]);
  }

  const handleNextQuestion = (selectedQuestion, currentOptionId) => {
    let data = questionData.options.map(option => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id
          }
        }
      } else {
        return option;
      }
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    setValue('options', data);
  }

  const showQuestionId = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      return questions.filter(question => question.id === id).map(data => data.id);
    }
    return 'Please choose one...';
  }

  const getNextQuestion = (questions, nextQuestionId) => {
    if (Array.isArray(nextQuestionId)) {
      const nextQuestion = [...questions].find(ele => nextQuestionId.includes(ele.id));
      if (nextQuestion) {
       // return nextQuestion
       return  { label : getTextByLanguage( language, nextQuestion.labels)};
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }
    } else {
      if (nextQuestionId.toLowerCase() === "end chat") {
        return { label: "End chat" };
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }
    }
  }

  return (
    <>
      <div className='mb-3'>
        <div className="row">
          <div className="col-md-12">
            <FormControl className='custom-input-box' size="small" fullWidth>
              <InputLabel sx={{ paddingRight: "14px",color:"#fff" }}>{staticTexts["chooseApi"]}</InputLabel>
              <Select
                label={staticTexts[""]}
                // sx={{ backgroundColor: "#F3F4F5" }}
                name='label'
                defaultValue={questionData.apiId}
                onChange={e => {

                  if (Array.isArray(questionData.options) && questionData.options?.length) {
                    if( questionData.options[0].value === "others" ) {
                      setValue(`options.${0}.value`, "others")
                    }
                  }
                  const api = state.apiList.find(f => f._id === e.target.value);
                  setValue(e.target.name, api?.name)
                  setValue("apiId", e.target.value)
                }}
                MenuProps={{
                  sx: {
                    minHeight: "250px",
                    height: "calc(100vh - 400px)",
                    width: "calc(50% - 20%)"
                  }
                }}
              >
                {
                  state.apiList?.map(api => {
                    return <MenuItem value={api._id}>{api.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>

      </div>

      <div>
        {/* For not manipulating the static options, inputs are hidden */}
        {
          (questionData.options.map((option, index) => {
            return (
              <React.Fragment key={index}>

                <input
                  className='d-none'
                  defaultValue={option.type}
                  {...register(`options.${index}.type`)}
                />
                {
                  option._id ?
                    <input
                      className='d-none'
                      defaultValue={option._id}
                      {...register(`options.${index}._id`)}
                    />
                    :
                    null
                }

                <input
                  className='d-none'
                  defaultValue={option.id}
                  {...register(`options.${index}.id`)}
                />
                <input
                  className='d-none'
                  defaultValue={option.next.type}
                  {...register(`options.${index}.next.type`)}
                />
                <input
                  className='d-none'
                  defaultValue={option.next.target}
                  {...register(`options.${index}.next.target`)}
                />

                <div className="single-choice-option-container">
                  <div className="message-container mb-0">
                  <label>{staticTexts["apiResponse"]}</label>
                    <div className="d-flex justify-content-between">
                      <div className="custom-input-box input-group mb-3">
                        <TextField
                          // label={staticTexts["apiResponse"]}
                          size="small"
                          fullWidth
                          className="form-control theme-input size-14"
                          placeholder={staticTexts["apiResponsePlaceholder"]}
                        //  defaultValue={getTextByLanguage(language,option.values)}
                          defaultValue={option.value}
                          {...register(`options.${index}.value`)}
                          disabled={option.value === "others" ? true : false}
                          InputProps={{
                            sx: {
                              fontSize: 14,
                              color : "#fff"
                            }
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: 14,
                              color : "#fff"
                            }
                          }}
                        />
                      </div>

                      {index === questionData.options.length - 1
                        ?
                        questionData.options.length === 1
                          ?
                          <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                            <img alt='plus' src={Plus} width={22} height={25} onClick={addOption} />
                          </div>
                          :
                          <div className="d-flex justify-content-between">
                            {option.value !== "others" && <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                              <img alt='minus' src={Minus} /*width={22} height={25}*/ onClick={() => option.value !== "others" && deleteOption(option)} />
                            </div>}
                            <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                              <img alt='plus' src={Plus} /*width={22} height={25}*/ onClick={addOption} />
                            </div>
                          </div>
                        :
                        option.value !== "others" &&
                        <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                          <img alt='minus' src={Minus} /*width={22} height={25}*/ onClick={() => option.value !== "others" && deleteOption(option)} />
                        </div>
                      }


                    </div>
                  </div>

                  <div className="custom-auto-complete">
                  <label>{staticTexts["goToNextMessage"]}</label>
                    <Autocomplete
                      disablePortal
                      value={
                        getNextQuestion(questions, showQuestionId(option.next.target))
                      }
                      options={[...questions, { label: "End chat" }]}
                      renderOption={(props, option) => (
                        <Tooltip title={option.label?.length > 40 ? option.label : ""} followCursor>
                          <MenuItem  {...props}
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize: 13
                            }}
                          >
                            {getTextByLanguage(language,option.labels)}
                          </MenuItem>
                        </Tooltip>
                      )}
                      onChange={(e, value, reason) => {
                        if (reason === "selectOption") {
                          const payload = value.label === "End chat"
                            ?
                            { type: 'question', id: 'end', label: 'End chat' }
                            :
                            value;
                          handleNextQuestion(payload, option.id);
                        }
                      }}
                      id="combo-box-demo"
                      renderInput={(params) =>
                        <TextField {...params}
                          // label={staticTexts['goToNextMessage']}
                        />
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            )
          }))
        }
      </div>


      <Button
        style={{ backgroundColor: "#5371FF", textTransform: "none", boxShadow: "none",width:"100%",borderRadius:"12px" }}
        variant="contained"
        onClick={() => {
          window.parent.postMessage("configApi", "*")
        }}
      >{staticTexts["configureApi"]}</Button>
    </>
  );
}