import { v4 as uuid } from 'uuid';
import { getStaticText } from '../assets/json/component-static-text/index';
import { createErrorMessage, getPlaceholderText } from './helper';
import { create } from '@mui/material/styles/createTransitions';
import { getErrorTexts } from '../assets/json/errorTexts';
import { keyboardTypes } from './helper';
import { textIcon } from '../assets/icons';
import { createTitles } from './create-question';
// function generateOptions(numOptions,type) {
//   const options = [];

//   for (let i = 1; i <= numOptions; i++) {
//     const option = {
//       type,
//       values: [],
//       id: uuid(),
//       next: { type: '', target: '' },
//     };
//     let country = ["India","Pakistan","Nepal","Bangladesh","Srilanka","Bhutan","USA","UK","UAE","Australia"];
//     // Populate values for different languages
//     ['en', 'hi', 'zh', 'ar', 'ur', 'pt', 'es', 'fr', 'de', 'ru'].forEach((lang,key) => {
//       option.values.push({
//         lang,
//         value: country[key],
//       });
//     });

//     options.push(option);
//   }

//   return options;
// }
export const addQuestionChecks = (botType, type, que, language) => {
  const staticTexts = getStaticText();
  const errorTexts = getErrorTexts();
  switch (type) {
    case 'button': {
      que.options = [
        {
          type,
          values: [
            { lang: 'en', value: 'Option 1' },        // English
            { lang: 'hi', value: 'विकल्प 1' },        // Hindi
            { lang: 'zh', value: '选项 1' },           // Chinese
            { lang: 'ar', value: 'الخيار 1' },        // Arabic
            { lang: 'ur', value: 'اختیار 1' },         // Urdu
            { lang: 'pt', value: 'Opção 1' },          // Portuguese
            { lang: 'es', value: 'Opción 1' },         // Spanish
            { lang: 'fr', value: 'Option 1' },         // French
            { lang: 'de', value: 'Option 1' },         // German
            { lang: 'ru', value: 'Вариант 1' }         // Russian
          ]
          ,
          id: uuid(),
          next: { type: '', target: '' },
        },
        {
          type,
          values: [
            { lang: 'en', value: 'Option 2' },        // English
            { lang: 'hi', value: 'विकल्प 2' },        // Hindi
            { lang: 'zh', value: '选项 2' },           // Chinese
            { lang: 'ar', value: 'الخيار 2' },        // Arabic
            { lang: 'ur', value: 'اختیار 2' },         // Urdu
            { lang: 'pt', value: 'Opção 2' },          // Portuguese
            { lang: 'es', value: 'Opción 2' },         // Spanish
            { lang: 'fr', value: 'Option 2' },         // French
            { lang: 'de', value: 'Option 2' },         // German
            { lang: 'ru', value: 'Вариант 2' }         // Russian
          ],
          id: uuid(),
          next: { type: '', target: '' },
        },
      ];
    // que.options = generateOptions(10,type)
     que.subType = {
       keyboardType : "Text",
       icon : "text",
       placeholderTexts : getPlaceholderText()
     }
        que = {
          ...que,
          source: '',
          header: 'Welcome ',
          footer: "Press '0' to restart and  '1' for live chat",
          button: 'Press',
          required: true,
          message: createErrorMessage(
            language,
            errorTexts['selectOne'],
            'selectOne'
          ),
        };

    }
    case 'name': {
      que.message = createErrorMessage(
        language,
        errorTexts['tooLengthy'],
        'tooLengthy'
      );
      que.maxRange = 100;
      return que;
    }
    case 'phone': {
      que.message = createErrorMessage(
        language,
        errorTexts['dontTrickMe'],
        'dontTrickMe'
      );
      if (botType?.toLowerCase() !== 'website') {
        que = {
          ...que,
          validation: true,
          required: true,
        };
      }
      return que;
    }
    case 'number': {
      que.message = createErrorMessage(
        language,
        errorTexts['dontTrickMe'],
        'dontTrickMe'
      );
      if (botType?.toLowerCase() !== 'website') {
        que = {
          ...que,
          validation: true,
          required: true,
        };
      }
      return que;
    }
    case 'email': {
      que.message = createErrorMessage(
        language,
        errorTexts['invalidEmail'],
        'invalidEmail'
      );
      if (botType?.toLowerCase() !== 'website') {
        que = {
          ...que,
          validation: true,
          required: true,
        };
      }
      return que;
    }
    case 'file': {
      que.subType = {
        ...que.subType,
        keyboardType : 'Image'
      }
      que.message = createErrorMessage(
        language,
        errorTexts['fileLimit'],
        'fileLimit'
      );
      return que;
    }
    case 'range': {
      que.rangePrefix = 'from';
      que.rangeSuffix = 'to';
      que.rangeStep = 5;
      que.minRange = 1;
      que.maxRange = 100;
      return que;
    }
    case 'video': {
      que.source = '';
      return que;
    }
    case 'image': {
      que.source = '';
      return que;
    }
    case 'document': {
      que.source = '';
      return que;
    }
    case 'contact': {
      que.links = [
        {
          titles: createTitles(language,'Open Link'),
          type: 'link',
          id: uuid(),
          value: 'https://example.com',
          openInNewTab: true,
        },
      ];
      return que;
    }
    case 'rating': {
      que.options = [
        { id: uuid(), type: 'rating', value: 'Terrible' },
        { id: uuid(), type: 'rating', value: 'Bad' },
        { id: uuid(), type: 'rating', value: 'OK OK' },
        { id: uuid(), type: 'rating', value: 'Good' },
        { id: uuid(), type: 'rating', value: 'Awesome' },
      ];
      return que;
    }
    case 'question': {
      botType?.toLowerCase() !== 'website'
        ? (que.required = true)
        : (que.required = false);
      return que;
    }
    case 'condition' : {
      que = {
        ...que,
        condition : {
          _attribute : "",
          cases : [
            {
            id : uuid(),
            case : '',
            values : [
              { lang: 'en', value: '' },   // English
              { lang: 'hi', value: '' },   // Hindi
              { lang: 'zh', value: '' },   // Chinese
              { lang: 'ar', value: '' },   // Arabic
              { lang: 'ur', value: '' },   // Urdu
              { lang: 'pt', value: '' },   // Portuguese
              { lang: 'es', value: '' },   // Spanish
              { lang: 'fr', value: '' },   // French
              { lang: 'de', value: '' },   // German
              { lang: 'ru', value: '' }    // Russian
            ],
            next: { type: '', target: '' }
          }
        ]
        }
      }
    }
    case 'AI': {
      que.queriesToHandle = 10;
      return que;
    }
    case 'api': {
      que.options = [
        { type, value: 'others', id: uuid(), next: { type: '', target: '' } },
        { type, value: '200', id: uuid(), next: { type: '', target: '' } },
        { type, value: '400', id: uuid(), next: { type: '', target: '' } },
      ];
      return que;
    }
    default: {
      return que;
    }
  }
};
