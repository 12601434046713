import { FLOW_LANGUAGE, LOAD_FLOW_LANGUAGE, UPDATE_FLOW_LANGUAGE } from "../actions/actions";

const initialState = {
    lang : 'en'
}

const langReducer = (state = initialState,action) => {
    switch(action.type){
        case LOAD_FLOW_LANGUAGE : {
            return state
        }
        case UPDATE_FLOW_LANGUAGE : {
            return {lang : action.payload}
        }
        default : {
            return state
        }
    }
}

export {langReducer}