import { UPDATE_TOAST } from '../actions/actions';

export const initialState = {
  type: 'success',
  show: false,
  description: 'description'
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TOAST: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export { toastReducer };