import { UPDATE_DIALOGUE } from '../actions/actions';

export const initialState = {
  deleteNodeDialogue: false,
  clearflowDialogue: false,
  deleteNodeData: undefined
};

const dialogueReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DIALOGUE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state;
    }
  }
};

export { dialogueReducer };