import { UPDATE_LOADER } from '../actions/actions';

export const initialState = false;

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOADER: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export { loaderReducer };