import { UPDATE_NON_BLOCK_LOADER } from '../actions/actions';

export const initialState = {
  isLoading: false,
  index: 0,
  loaderPosition: "top"
};

const loaderNonBlockReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NON_BLOCK_LOADER: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export { loaderNonBlockReducer };