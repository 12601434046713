import './name.scss';
import Drawer from '@mui/material/Drawer';
import EditComponent from '../EditComponent';
import ComponentList from '../ComponentList'
import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { getComponentName } from '../../../utilities/react-flow/nodes';
import { getClass } from '../../../utilities/react-flow/nodes';
import exclamation from '../../../assets/images/exclamation.svg';
import name from '../../../assets/images/chat-components/name.svg';
import date from '../../../assets/images/chat-components/date.svg';
import file from '../../../assets/images/chat-components/file.svg';
import email from '../../../assets/images/chat-components/email.svg';
import phone from '../../../assets/images/chat-components/phone.svg';
import image from '../../../assets/images/chat-components/image.svg';
import range from '../../../assets/images/chat-components/range.svg';
import video from '../../../assets/images/chat-components/video.svg';
import rating from '../../../assets/images/chat-components/rating.svg';
import number from '../../../assets/images/chat-components/number.svg';
import contact from '../../../assets/images/chat-components/contact.svg';
import redirect from '../../../assets/images/chat-components/redirect.svg';
import question from '../../../assets/images/chat-components/question.svg';
import location from '../../../assets/images/chat-components/location.svg';
import catalogue from '../../../assets/images/chat-components/catalogue.svg';
import statement from '../../../assets/images/chat-components/statement.svg';
import live_chat from '../../../assets/images/chat-components/live_chat.svg';
import button from '../../../assets/images/chat-components/single-choice.svg';
import defaultImage from '../../../assets/images/chat-components/default.svg';
import appointment from '../../../assets/images/chat-components/appointment.svg';
import multi_select from '../../../assets/images/chat-components/multi_select.svg';
import smart_question from '../../../assets/images/chat-components/smart_question.svg';
import address from '../../../assets/images/chat-components/address.svg';
import single_product from '../../../assets/images/chat-components/single_product.svg';
import multi_product from '../../../assets/images/chat-components/multi_product.svg';
import chatGPT from '../../../assets/images-v1/ChatGPT.png';
import AI from '../../../assets/images-v1/ChatGPT.png';
import template from '../../../assets/images-v2/template.png';
import api from '../../../assets/images-v2/api.png';
import { getStaticText } from '../../../assets/json/component-static-text/index';
import { getTooltipText } from '../../../assets/json/component-tooltip/index';

// Tabs
import { TabPanel } from "../../mui";
import { a11yProps } from "../../mui";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useDispatch, useSelector } from 'react-redux';
import { LOAD_ACTIVE_QUESTION, LOAD_FLOW, UPDATE_FLOW, UPDATE_LOADER, UPDATE_NODE, UPDATE_TOAST } from '../../../redux/actions/actions';
import { BotMethods } from '../../../services/bot';
import { Tooltip } from '@mui/material';
import QuickActions from '../QuickActions';


const images = { AI, appointment, redirect, catalogue, defaultImage, question, statement, contact, date, image, name, email, button, video, live_chat, file, document: file, location, multi_select, phone, number, range, rating, smart_question, chatGPT, address, single_product, multi_product, template, api };

const staticTexts = getStaticText();
const tooltipTexts = getTooltipText();
export default function Nodes(props) {
  const dispatch = useDispatch();

  const [modal, showModal] = useState(false);
  const [toolTip, setToolTip] = useState(false);
  // const [hideList, setHideList] = useState(false);
  const [showFlowComponents, setShowflowComponents] = useState(false);

  const flow = useSelector(state => state.flow);
  const { updateBot } = BotMethods();

  const allEdges = useSelector(state => state.edges);
  const connectedNodes = useSelector(state => state.connectedNodes);
  const activeQuestion = useSelector(state => state.activeQuestion);
  const toggleDrawer = (open) => (event) => {

    if (props.id !== 'initial node' && !props.id.includes('child-options')) {
      /**
       * Loading Active Question into the Store
       */
      dispatch({ type: LOAD_ACTIVE_QUESTION, payload: props.data });
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      showModal(open)
    }

    if (props.id.includes('child-options')) {
      /** Loading option parent into the store */
      const optionParent = flow.questions.find(que => que.id === props.data.optionParentId);
      dispatch({ type: LOAD_ACTIVE_QUESTION, payload: optionParent });
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      showModal(open)
    }

  };

  const handleConnect = (event) => {
    const { source, target } = event;
    const isConnected = allEdges.some(edge => edge.target === target);


    if (!isConnected) {
      const sourceComponent = flow.questions.find(f => f.id === source);
      const targetComponent = flow.questions.find(f => f.id === target);
      const sourceComponentIndex = flow.questions.findIndex(f => f.id === source);

      const updatedFlow = flow.questions.map(component => {
        if (component.id === source) {
          return { ...sourceComponent, next: { ...component.next, target } }
        }

        return component;
      })
        .filter(component => component.id !== target);

      updatedFlow.splice(sourceComponentIndex + 1, 0, targetComponent);
      dispatch({ type: LOAD_FLOW, payload: { ...flow, questions: updatedFlow } });

      updateBot({ action: "edit", questionData: updatedFlow.find(f => f.id === source) })
        .then((response) => {
          dispatch({ type: UPDATE_LOADER, payload: false });
          dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
        })
        .catch(error => {
          dispatch({ type: UPDATE_LOADER, payload: false });
          console.log(error);
        })
    } else {
      dispatch({
        type: UPDATE_TOAST,
        payload: { show: true, type: 'info', description: 'Target component must be detached first' }
      });
    }
  }

  const toggleFlowComponents = () => {
    setShowflowComponents(!showFlowComponents);

    if (!showFlowComponents) {
      dispatch({ type: UPDATE_NODE, payload: { id: props.data.id, selected: true } });
    }
  }

  const checkIsConnectable = () => {

    const isConnected = allEdges.find(edge => edge.target === props.data.id);
    return isConnected ? false : true;
  };

  const isConnected = () => {

    if (props.id.includes('child-options')) {
      const optionParent = (flow.questions || []).find(que => que.id === props.data.optionParentId);
      const optionId = props.id.split('-child-options-')[0];
      const option = (optionParent?.options || []).find(op => op.id === optionId)

      return !!option?.next?.target;
    }

    return (connectedNodes || []).includes(props.data.id);
  }

  const truncate = (text) => {
    if ((text.length) > 100) {
      return text.substring(0, 80) + '...';
    }
    return text;
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{ cursor: (props.id === 'initial node') ? 'default' : 'grab' }}
        className={`bp-node ${(props.id === 'initial node') ? '' : 'bp-node-hover'}`}
        onMouseEnter={() => setToolTip(true)}
        onMouseLeave={() => setToolTip(false)}
      >

        {
          !props.id.includes("-child-options-") &&
          <QuickActions toggleDrawer={toggleDrawer} flow={flow} question={props.data} nodeId={props.id} />
        }

        {
          !isConnected() &&
          <div className='unconnected-error'>
            <Tooltip placement="top-start" title={tooltipTexts.componentLyingDisconnectedFromYourFlow} >
              <img alt='img' src={exclamation} width={17.5}></img>
            </Tooltip>
          </div>
        }

        {props.id !== 'initial node' && (<>

          <Handle type="target" className={`${checkIsConnectable() && 'target-connectable'}`} position={Position.Top} isConnectable={checkIsConnectable} />

          {checkIsConnectable() &&
            <div className="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
          }
        </>
        )}

        {!(connectedNodes || []).includes(props.data.id) &&
          (<>
            <div data-bs-toggle="dropdown" aria-expanded="false">
              <Handle
                type="source"
                onConnect={handleConnect}
                position={Position.Bottom}
                onClick={toggleFlowComponents}
                isConnectable={!(connectedNodes || []).includes(props.data.id)}
                className={`${!(connectedNodes || []).includes(props.data.id) && props.data.type !== "api" && 'react-flow__handle-bp'}`}
              />
              {props.data.type !== "api" && <div className="arrow-source">
                <span></span>
                <span></span>
                <span></span>
              </div>}
            </div>
            <ul className="re-position dropdown-menu p-0" style={{ width: "100%", top: "55px" }}>
              <ComponentList componentId={props.data.id} toggleFlowComponents={toggleFlowComponents} handleModal={showModal} />
            </ul>
          </>
          )
        }
        {
          (connectedNodes || []).includes(props.data.id) &&
          <Tooltip title="" style={{ zIndex: 9999 }}>
            <Handle
              type="source"
              onConnect={handleConnect}
              position={Position.Bottom}
              onClick={toggleFlowComponents}
              isConnectable={!(connectedNodes || []).includes(props.data.id)}
              className={`${!(connectedNodes || []).includes(props.data.id) && 'react-flow__handle-bp'}`}
            />
          </Tooltip>
        }

        {/* IMAGE SECTION */}
        {
          props.data.type === 'AI' &&
          <div className="chatGPT" onClick={toggleDrawer(true)}>
            <img src={images[props.id === 'initial node' ? 'defaultImage' : props.data.type]} alt="images"></img>
            <div className="content d-flex flex-column justify-content-center">
              <span className="node-title">{truncate(props.data.label || '') || truncate(props.data.value || '')}</span>
            </div>
          </div>
        }
        {
          props.data.type !== 'AI' &&
          <div className={`handler ${getClass('border', props.data)}`} onClick={toggleDrawer(true)}>
            <div className={`d-flex justify-content-center align-items-center circle ${getClass('circle', props.data)}`}>
              <img
                className="component-img"
                src={images[props.id === 'initial node' ? 'defaultImage' : props.data.type]} alt="images"
                style={{ height: props.data.type === "template" && 20 }}
              >

              </img>
            </div>
            <div className="content d-flex flex-column justify-content-center">
              <span className="node-title">{truncate(props.data.label || '') || truncate(props.data.value || '')}</span>
            </div>
          </div>
        }

        {/* {
          showFlowComponents
            ? <ComponentList toggleFlowComponents={toggleFlowComponents} handleModal={showModal} />
            : null
        } */}

        <Drawer
          anchor={'right'}
          open={modal}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: { width: "30%", minWidth: "340px" },
          }}
        >
          <QuestionCustomizationLayout activeQuestion={activeQuestion} showModal={showModal} />
        </Drawer>


        {toolTip &&
          <div className='tool-tip-components'>
            {getComponentName(props.data)}
          </div>
        }

      </div >
    </div>

  );
}

function QuestionCustomizationLayout({ activeQuestion, showModal }) {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChange} style={{ fontWeight: "200" }} TabIndicatorProps={{ style: { background: "#212529" } }}>
            <Tab label={staticTexts['customize']} {...a11yProps(0)} style={{ color: "#212529", textTransform: "capitalize" }} />
            <Tab label={staticTexts['advance']} {...a11yProps(1)} style={{ color: "#212529", textTransform: "capitalize" }} />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <EditComponent tab={"customize"} data={activeQuestion} handleModal={showModal} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <EditComponent tab={"advance"} data={activeQuestion} handleModal={showModal} />
        </TabPanel>
      </Box>
    </>
  );
}