import { UPDATE_ACTIVE_EDGE } from '../actions/actions';

export const initialState = '';

const activeEdgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_EDGE: {
      return action.payload
    }
    default: {
      return state;
    }
  }
};

export { activeEdgeReducer };