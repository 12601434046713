import React from 'react';
import './single-choice.css';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import {
  UPDATE_ACTIVE_QUESTION,
  UPDATE_TOAST,
} from '../../../../redux/actions/actions';
// import Plus from "../../../../assets/images/plus.svg";
// import Minus from "../../../../assets/images/remove_circle_black.svg";
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { plusIcon as Plus, minusIcon as Minus } from '../../../../assets/icons';
import { createOptions, getNewOptions, getOptionValue, getTextByLanguage } from '../../../../utilities/helper';

export default function SingleChoice(props) {
  const methods = useFormContext();
  const botType = useSelector((state) => state.currentBotDetails.botType);
  const currentQuestion = useSelector((state) => state.activeQuestion);
  const questions = useSelector((state) =>
    state.flow.questions.filter((item) => item.id !== currentQuestion.id)
  );
  
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.lang)
  const staticTexts = getStaticText(language);
  const nextQuestionOptions = questions.map((ques)=> (
    {
      label : getTextByLanguage(language,ques.labels),
      id : ques.id
    }
  ))
 
  const addOption = () => {
    if (botType === 'whatsapp' && currentQuestion.options.length >= 10) {
      return dispatch({
        type: UPDATE_TOAST,
        payload: {
          show: true,
          type: 'error',
          description: 'Whatsapp only allows max 10 options.',
        },
      });
    }
    const index = (methods.getValues().options || []).length;
    let option = {
      id: uuid(),
      type: 'button',
      //value: `Option ${index + 1}`,
      value: getOptionValue(language, index),
      next: {
        type: '',
        target: '',
      },
    };

    // Updating Active Question value in the store
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        options: [
          ...currentQuestion.options,
          {
            id: option.id,
            type: option.button,
            next: option.next,
            values: getNewOptions(index),
          },
        ],
      },
    });
    // Updating values of the form array using react-hook form inbuilt-method
    methods.setValue('options', [
      ...methods.getValues().options,
      { ...option },
    ]);
  };

  const deleteOption = (option) => {
    let data = methods
      .getValues()
      .options.filter((data) => data.id !== option.id);
  
     dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: createOptions(language,data) } });
    // Updating values of links array using react-hook form inbuilt-method
    methods.setValue('options', data);
  };

  const handleNextQuestion = (selectedQuestion, currentOptionId) => {
    let data = currentQuestion.options.map((option) => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id,
          },
        };
      } else {
        return option;
      }
    });
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    methods.setValue('options', data);
  };

  const showQuestionId = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      const question = questions
      .filter((question) => question.id === id)
      .map((data) => data.id);
      return question;
    }
    return 'Please choose one...';
  };

  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '20':
        return 20;
      case '1024':
        return 1024;
      default:
        return 99999;
    }
  };

  const getNextQuestion = (questions, nextQuestionId) => {
    if (Array.isArray(nextQuestionId)) {
      const nextQuestion = [...questions].find((ele) =>
        nextQuestionId.includes(ele.id)
      );
      if (nextQuestion) {
        return { label : getTextByLanguage( language, nextQuestion.labels)};
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }

    } else {
      if (nextQuestionId.toLowerCase() === 'end chat') {
        return { label: 'End chat' };
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }
    }
  };

  return (
    <div style={{ paddingTop: 10 }}>
      {botType?.toLowerCase() === 'whatsapp' &&
      (methods.getValues().options || []).length <= 3 ? (
        <>
          <label htmlFor="">{staticTexts['imageUrl']}</label>
          <div className="custom-input-box input-group mb-4">
            <TextField
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              defaultValue={currentQuestion.source}
              {...methods.register('source')}
              placeholder="https://example.com"
              aria-label="Username"
              aria-describedby="basic-addon1"
              InputProps={{
                sx: {
                  fontSize: 14,
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                },
              }}
            />
          </div>
        </>
      ) : null}
      {botType === 'whatsapp' &&
      (methods.getValues().options || []).length > 3 ? (
        <div className="custom-input-box input-group mb-4">
          <TextField
            label={staticTexts['header']}
            size="small"
            fullWidth
            className="form-control theme-input size-14"
            defaultValue={currentQuestion.header}
            inputProps={{ maxLength: 20 }}
            {...methods.register('header')}
            placeholder={staticTexts['welcome']}
            aria-label="Welcome"
            aria-describedby="basic-addon1"
            InputProps={{
              sx: {
                fontSize: 14,
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              },
            }}
          />
        </div>
      ) : null}
      {botType === 'whatsapp' &&
      (methods.getValues().options || []).length <= 3 ? (
        <>
          <label>{staticTexts['footer']}</label>
          <div className="custom-input-box input-group mb-4">
            <TextField
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              inputProps={{ maxLength: 60 }}
              defaultValue={currentQuestion.footer}
              {...methods.register('footer')}
              placeholder={staticTexts['pressZeroToRestartAndOneToLiveChat']}
              aria-label="Press '0' to restart and  '1' for live chat"
              aria-describedby="basic-addon1"
              InputProps={{
                sx: {
                  fontSize: 14,
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                },
              }}
            />
          </div>
        </>
      ) : null}
      {botType?.toLowerCase() === 'whatsapp' ? (
        <>
          <label>{staticTexts['body']}</label>
          <div className="custom-input-box input-group mb-4">
            <TextField
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              //defaultValue={currentQuestion.label}
              inputPropsxLe={{ mangth: getWhatsAppLimits('1024') }}
              {...methods.register('label', {
                required: 'Message is Required',
              })}
              placeholder={staticTexts['enterYourMessage']}
              InputProps={{
                sx: {
                  fontSize: 14,
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                },
              }}
            />
          </div>
        </>
      ) : null}

      {/* {botType !== 'website' ? (
        <>
          <label>{staticTexts['errorMessageIfUserEntersWrongInput']}</label>
          <div className="custom-input-box input-group mb-4">
            <TextField
              size="small"
              fullWidth
              className="form-control theme-input size-14"
              defaultValue={getTextByLanguage(language,currentQuestion.message)}
              //defaultValue="hereeee"
              inputProps={{ maxLength: getWhatsAppLimits('1024') }}
              {...methods.register('message.error')}
              placeholder={
                staticTexts['pleaseSelectOptionFromAboveGivenChoices']
              }
              aria-label="Please select an option from above given choices!"
              InputProps={{
                sx: {
                  fontSize: 14,
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                },
              }}
            />
          </div>
        </>
      ) : null} */}

      {botType === 'whatsapp' &&
      (methods.getValues().options || []).length > 3 ? (
        <div className="message-container">
          <p className="description-headings">Button</p>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control theme-input size-14"
              defaultValue={currentQuestion.button}
              inputProps={{ maxLength: getWhatsAppLimits('20') }}
              {...methods.register('button')}
              placeholder={staticTexts['press']}
              aria-label="Press"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      ) : null}
      {currentQuestion.options.map((option, index) => {
        return (
          <React.Fragment key={index}>
            {/* For not manipulating the static options, inputs are hidden */}
            <input
              className="d-none"
              defaultValue={option.type}
              {...methods.register(`options.${index}.type`)}
            />
            {option._id ? (
              <input
                className="d-none"
                defaultValue={option._id}
                {...methods.register(`options.${index}._id`)}
              />
            ) : null}

            <input
              className="d-none"
              defaultValue={option.id}
              {...methods.register(`options.${index}.id`)}
            />
            <input
              className="d-none"
              defaultValue={option.next.type}
              {...methods.register(`options.${index}.next.type`)}
            />
            <input
              className="d-none"
              defaultValue={option.next.target}
              {...methods.register(`options.${index}.next.target`)}
            />
            {/* End of hidden inputs */}
            <div className="single-choice-option-container">
              <div className="message-container mb-0">
                <label>{staticTexts['titleWithoutAsterisk']}</label>
                <div className="d-flex justify-content-between">
                  <div className="custom-input-box input-group mb-3">
                    <TextField
                      size="small"
                      key={getTextByLanguage(language,option.values)}
                      fullWidth
                      className="form-control theme-input size-14"
                      placeholder={staticTexts['option']}
                      defaultValue={getTextByLanguage(language,option.values)}
                      inputProps={{ maxLength: getWhatsAppLimits('20') }}
                      {...methods.register(`options.${index}.value`)}
                      InputProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  </div>

                  {index === currentQuestion.options.length - 1 ? (
                    currentQuestion.options.length === 1 ? (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: '10px', height: '40px' }}
                        role="button"
                      >
                        <img
                          alt="plus"
                          src={Plus}
                          onClick={addOption}
                        />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: '10px', height: '40px' }}
                          role="button"
                        >
                          <img
                            alt="minus"
                            src={Minus}
                            onClick={() =>
                            deleteOption(option)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: '10px', height: '40px' }}
                          role="button"
                        >
                          <img
                            alt="plus"
                            src={Plus}
                            onClick={addOption}
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ marginLeft: '10px', height: '40px' }}
                      role="button"
                    >
                      <img
                        alt="minus"
                        src={Minus}
                        onClick={() =>
                        deleteOption(option)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="custom-auto-complete">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={getNextQuestion(
                    questions,
                    showQuestionId(option.next.target)
                  )}
                  options={[...nextQuestionOptions, { label: 'End chat',id : 'end' }]}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ''}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                         // '&:hover': { backgroundColor: '#E4DEF5' },
                          fontSize: 13,
                        }}
                      >
                       {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === 'selectOption') {
                      const payload =
                        value.label === 'End chat'
                          ? { type: 'question', id: 'end', label: 'End chat' }
                          : value;
                      handleNextQuestion(payload, option.id);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <>
                      <label>{staticTexts['goToNextMessage']}</label>
                      <TextField
                        {...params}
                        // label={staticTexts['goToNextMessage']}
                      />
                    </>
                  )}
                  noOptionsText={
                    <span style={{ color: 'white',fontSize:'13px' }}>No matching options</span>
                  }
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
