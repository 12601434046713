import { LOAD_EDGES, ADD_EDGE, DELETE_EDGE, UPDATE_EDGE } from '../actions/actions';

export const initialState = [];

const edgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EDGE: {
      return [
        ...state,
        ...action.payload,
      ];
    }
    case UPDATE_EDGE: {
      return [
        ...state.map(item => {
          if (String(item._id) === action.payload?._id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        })
      ]
    }
    case LOAD_EDGES: {
      return action.payload;
    }
    case DELETE_EDGE: {
      return state.filter(d => d.id !== action.payload.id);
    }
    default: {
      return state;
    }
  }
};

export { edgeReducer };