import './delete-node.css';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { BotMethods } from '../../../services/bot';
import {
  LOAD_ACTIVE_QUESTION,
  UPDATE_DIALOGUE,
  UPDATE_FLOW,
  UPDATE_LOADER,
  UPDATE_TOAST,
} from '../../../redux/actions/actions';
import { getStaticText } from '../../../assets/json/component-static-text/index';
import { crossIcon } from '../../../assets/icons';
import { useState } from 'react';
import ButtonLoader from '../ButtonLoader';
import { successTickMark } from '../../../assets/icons';

export default function DeleteNode({ handleModal }) {
  const { deleteNodeDialogue, deleteNodeData } = useSelector(
    (state) => state.dialogues
  );
  const dispatch = useDispatch();
  const language = useSelector((state)=> state.language.lang)
  const staticTexts = getStaticText(language);
  const { updateBot } = BotMethods();
  const [buttonLoader,setButtonLoader] = useState(false);
  const [isDeleted,setIsDeleted] = useState(false)

  const handleBackButton = () => {
    setIsDeleted(false)
    dispatch({
      type: UPDATE_DIALOGUE,
      payload: { deleteNodeDialogue: false },
    });
  }
  const handleDelete = () => {
    let question = deleteNodeData;
    setButtonLoader(true)

  // dispatch({ type: UPDATE_LOADER, payload: true });
    updateBot({ questionData: question, action: 'delete' })
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: LOAD_ACTIVE_QUESTION,
          payload: response.questions
            ? response.questions[0]
            : response.flows[0].questions[0],
        });
        setButtonLoader(false)
        setIsDeleted(true)
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
      });
  };

  const handleClose = () => {
    dispatch({ type: UPDATE_DIALOGUE, payload: { deleteNodeDialogue: false } });
    setIsDeleted(false)
  };

  return (
    deleteNodeDialogue && (
      <>
        <div>
          <Dialog
            open={deleteNodeDialogue}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div className="delete-node-container">
              <div
                className="delete-node-cross-icon"
                onClick={handleClose}
              >
                <img
                  className="text-white cross-icon"
                  style={{
                    background: '#000',
                    borderRadius: '50%',
                    padding: '6px',
                    fontSize: '18px',
                  }}
                  src={crossIcon}
                />
              </div>
              <div className="d-flex flex-column delete-node-child">
                { (isDeleted) && <div className="delete-node-img-container">
                  <img src={successTickMark} alt='tick-mark' />
                </div>
                }

                <div>
                  <p className="delete-node-title delete-node-title-text">
                    {isDeleted ? "Deleted Sucessfully " : staticTexts['areYouSureToClearThisQuestion']}
                  </p>
                </div>

                {/* <div className="delete-node-content">
                  <p style={{ color: "#999999" }}>{staticTexts['areYouSureToClearThisQuestion']}</p>
                </div> */}

                {/* Buttons */}
                <div className="delete-node-buttons">
                  {/* <button onClick={handleClose} className="btn theme-button"
                    style={{ marginRight: "15px", backgroundColor: "#DFE1E4" }}>
                    {staticTexts['cancel']}</button> */}
                  <button
                    onClick={isDeleted? handleBackButton : handleDelete}
                    className={!isDeleted ? "btn theme-button delete-btn-new":'btn theme-btn back-btn'}
                  >
                    {(!buttonLoader && !isDeleted) && staticTexts['yesDelete']}
                    {isDeleted && "Back"}
                    {buttonLoader && <ButtonLoader/>}
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    )
  );
}
