import './App.css';
import { useEffect, useState } from 'react';
import Canvas from './components/tree-ui/Canvas';
import { useDispatch } from 'react-redux';
import Loader from './components/Shared/Loader';
import { httpRequest } from './services/network';
import NotFound from './components/Shared/NotFound';
import {
  LOAD_FLOW,
  UPDATE_LOADER,
  LOAD_ACTIVE_QUESTION,
  LOAD_BOT,
  LOAD_ATTRIBUTES,
  LOAD_CURRENT_BOT_DETAILS,
  LOAD_AGENCY,
  LOAD_FLOW_LANGUAGE,
  LOAD_ALL_FLOW,
  LOAD_ALL_BOT,
  LOAD_ALL_API
} from './redux/actions/actions';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import OldLayout from './components/old-ui/Layout';
import DeleteNode from './components/Shared/Dialog';
import { BotMethods } from './services/bot';
import { useSelector } from 'react-redux';

function App() {
  let { getAttributes,getAllFlows,getAllBots,getApis } = BotMethods();
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState('v2');
  sessionStorage.clear();

  // useEffect(() => {

  //   // eslint-disable-next-line
     const [empty, token, flowId, botType, language] = location.pathname.split('/');
     localStorage.setItem("token", token);
 
  useEffect(() => {
    //get flow details using flow id
        getAttributes().then(attributes => {
      dispatch({ type: LOAD_ATTRIBUTES, payload: attributes });
    });
    getAllBots().then((res)=> {
      dispatch({type:LOAD_ALL_BOT,payload:res})
    })
    getApis().then((res)=> {
      dispatch({type:LOAD_ALL_API,payload:res})
    })
    dispatch({ type: UPDATE_LOADER, payload: true });
  
    httpRequest('GET', `/bot-builder/flows/${flowId}`)
      .then((response) => {
        dispatch({
          type: LOAD_FLOW,
          payload: response.data[0]?.flows[0] || response.data || [],
        });
        const flow = response.data[0]?.flows[0]?.questions
          ? response.data[0]?.flows[0]?.questions[0]
          : response.data?.questions
          ? response.data?.questions[0]
          : [];
        window.parent.postMessage(
          {
            flowName:
              response.data[0]?.flows[0]?.name || response.data.name || '',
            type: 'flowName',
          },
          '*'
        );
        dispatch({ type: LOAD_ACTIVE_QUESTION, payload: flow });
        //  getAgency();
      })
      .catch((err) => {
        // navigate("/error", { replace: true });
      })
      .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));
 
      getAllFlows()
      .then((res)=>{
        dispatch({type:LOAD_ALL_FLOW,payload:res})
      })
    //getting details of bot
    dispatch({ type: UPDATE_LOADER, payload: true });
    // httpRequest('GET', '/bot/653b6cee289ae44fdf5a0aa7')
    //   .then((response) => {
    //     dispatch({
    //       type: LOAD_BOT,
    //       payload: response.data[0] || response.data || [],
    //     });
    //   })
    //   .catch((err) => {
    //     // navigate("/error", { replace: true });
    //   })
    //   .finally(() => dispatch({ type: UPDATE_LOADER, payload: false }));

    // getAttributes().then((attributes) => {
    //   dispatch({ type: LOAD_ATTRIBUTES, payload: attributes });
    // });
  }, []);

  
  return (
    <ReactFlowProvider>
      <div className="App">
        <DeleteNode />
        {
          <div style={{ height: '1px' }}>
            <Loader />
            <Routes>
              <Route
                path="/error"
                index
                element={<NotFound />}
              />
              {(
                <Route
                  path={`${token}/${flowId}`}
                  element={<OldLayout />}
                />
              )}
              
            </Routes>
          </div>
        }
      </div>
    </ReactFlowProvider>
  );
}

export default App;
