import { useSelector } from "react-redux";
import { httpRequest } from "./network";
import { reject } from "lodash";

export const BotMethods = () => {
  const botId = useSelector(state => state.currentBotDetails.botId);
  const flowId = useSelector(state => state?.flow?._id)
  // const flowId = "656040f4a24c432b86d36093";
  const botType = useSelector(state => state.currentBotDetails.botType);

  const getFlowUrl = () => {
       return `bot-builder/flows/flow-question/${flowId}`
     //  return `flows/flow-question/656040f4a24c432b86d36093`;
  }

  const updateBot = (data) => new Promise((resolve, reject) => {
    const url = getFlowUrl(botType);
    const payload = { ...data, _id: undefined, createdAt: undefined };
    httpRequest('PUT', url, payload)
      .then(({ data }) => {
        resolve(data || { questions: [] });
      })
      .catch(error => {
        reject(error);
      });
  });

  const getTemplates = () => new Promise((resolve, reject) => {
    httpRequest('GET', `whatsapp-automation/templates/get-facebook-templates/${botId}`)
    .then(({ data }) => {
      resolve(data || { questions: [] });
    })
    .catch(error => {
      reject(error);
    });
  });  

  const getAttributes = () => new Promise((resolve, reject) => {
   // httpRequest('GET', `bot-builder/attributes`)
   httpRequest('GET','/bot-builder/attributes')
    .then(({ data }) => {
      resolve(data || { questions: [] });
    })
    .catch(error => {
      reject(error);
    });
  });  

  const getApis = () => new Promise((resolve, reject) => {
    httpRequest('GET', `/bot-builder/api-requests?limit=1000`, { createdAt: true })
    .then(({ data }) => {
      resolve(data || { questions: [] });
    })
    .catch(error => {
      reject(error);
    });
  });  


  const getAllFlows = () => new Promise((resolve,reject)=>{
    httpRequest('GET',`/bot-builder/flows?limit=10000000000`)
    .then(({data}) => {
      resolve (data)
    })
    .catch(error => {
      reject(error)
    })
  })

  const getAllBots = () => new Promise((resolve,reject)=>{
    httpRequest('GET',`/bot-builder/bots`)
    .then(({data}) => {
      resolve (data)
    })
    .catch(error => {
      reject(error)
    })
  })

  return { updateBot, getTemplates, getAttributes, getApis,getAllFlows,getAllBots };

}
