import React from 'react';
import './multiple-choice.css';
import '../edit-component.css'
import { useFormContext } from "react-hook-form";
import { UPDATE_ACTIVE_QUESTION } from '../../../../redux/actions/actions';
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useDispatch } from 'react-redux';
import { TextField } from "@mui/material";
//import Plus from "../../../../assets/images/plus.svg";
//import Minus from "../../../../assets/images/remove_circle_black.svg";
import {plusIcon as Plus, minusIcon as Minus} from '../../../../assets/icons'
import { getStaticText } from '../../../../assets/json/component-static-text/index';

export default function MulitpleChoice(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const optionData = useSelector(state => state.activeQuestion.options);
  const currentQuestion = useSelector(state => state.activeQuestion);
  const language = useSelector(state => state.language.lang)
  const staticTexts = getStaticText(language);

  const addOption = () => {
    const index = (methods.getValues().options || []).length;
    let option = {
      id: uuid(),
      type: 'multi_select',
      value: `Option ${index + 1}`,
      next: {
        type: '',
        target: ''
      }
    };
    // Updating Active Question value in the store
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: [...optionData, { ...option }] } });
    // Updating values of the form array using react-hook form inbuilt-method
    // methods.setValue('options', [...optionData, { ...option }]);
    methods.setValue('options', [...methods.getValues().options, { ...option }]);

  }

  const deleteOption = (option) => {
    let data = methods.getValues().options.filter(data => data.id !== option.id)
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { options: data } });
    // Updating values of links array using react-hook form inbuilt-method
    methods.setValue('options', data);
  }

  return (
    <div className='pt-3'>
      {optionData.map((option, index) => {
        return (
          <React.Fragment key={index}>
            <div className="message-container">
              {/* For not manipulating the static options, inputs are hidden */}
              <input
                className='d-none'
                defaultValue={option.type}
                {...methods.register(`options.${index}.type`)}
              />
              {option._id ?
                <input
                  className='d-none'
                  defaultValue={option._id}
                  {...methods.register(`options.${index}._id`)}
                />
                :
                null
              }

              <input
                className='d-none'
                defaultValue={option.id}
                {...methods.register(`options.${index}.id`)}
              />
              <input
                className='d-none'
                defaultValue={option.next.type}
                {...methods.register(`options.${index}.next.type`)}
              />
              <input
                className='d-none'
                defaultValue={option.next.target}
                {...methods.register(`options.${index}.next.target`)}
              />
              {/* End of hidden inputs */}
              {/* <p className="description-headings">Title</p> */}
              <div className="d-flex custom-input-box justify-content-between">
                <TextField
                  label={staticTexts['titleWithoutAsterisk']}
                  size="small"
                  // fullWidth
                  placeholder={staticTexts['option']}
                  defaultValue={option.value}
                  className="form-control size-14 theme-input"
                  {...methods.register(`options.${index}.value`)}
                  InputProps={{
                    sx: {
                      fontSize:14, 
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize:14,
                    }
                  }}
                />

                {index === currentQuestion.options.length - 1
                  ?
                  currentQuestion.options.length === 1 ?
                    <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                      <img src={Plus} alt='Plus' onClick={addOption} />
                    </div>
                    :
                    <div className="d-flex justify-content-between">
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Minus} alt='Minus' onClick={() => deleteOption(option)} />
                      </div>
                      <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                        <img src={Plus} alt='Plus' onClick={addOption} />
                      </div>
                    </div>
                  :
                  <div className='d-flex align-items-center' style={{ marginLeft: "10px", height: "40px" }} role="button">
                    <img src={Minus} alt='Minus' onClick={() => deleteOption(option)} />
                  </div>
                }

              </div>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  );
}