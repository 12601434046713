import {LOAD_ALL_FLOW} from '../actions/actions';

export const initialState = [];

const allFlowReducer = (state = initialState,action) => {
    switch(action.type) {
        case LOAD_ALL_FLOW: {
            return [
                ...action.payload
            ]
        }
        default : {
            return state
        }
    }
}

export { allFlowReducer };