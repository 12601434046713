import hindiTexts from './hi.json';
import englishTexts from './en.json';
import urduTexts from './ur.json';
import chineseTexts from './zh.json';
import spanishTexts from './es.json';
import germanTexts from './de.json';
import portugueseTexts from './pt.json';
import russianTexts from './ru.json';
import arabicTexts from './ar.json';
import frenchTexts from './fr.json';

const getErrorTexts = (language) => {
  switch (language) {
    case 'en':
      return englishTexts;
    case 'hi':
      return hindiTexts;
    case 'ur':
      return urduTexts;
    case 'zh':
      return chineseTexts;
    case 'es':
      return spanishTexts;
    case 'de':
      return germanTexts;
    case 'pt':
      return portugueseTexts;
    case 'ru':
      return russianTexts;
    case 'ar':
      return arabicTexts;
    case 'fr':
      return frenchTexts;
    default:
      // Default to English if the specified language is not found
      return englishTexts;
  }
};

export { getErrorTexts };
