import React from 'react';
//import './single-choice.css';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import {
  UPDATE_ACTIVE_QUESTION,
} from '../../../../redux/actions/actions';
// import Plus from "../../../../assets/images/plus.svg";
// import Minus from "../../../../assets/images/remove_circle_black.svg";
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { plusIcon as Plus, minusIcon as Minus } from '../../../../assets/icons';
import { getTextByLanguage } from '../../../../utilities/helper';

export default function Conditions(props) {
  const methods = useFormContext();
  const botType = useSelector((state) => state.currentBotDetails.botType);
  const currentQuestion = useSelector((state) => state.activeQuestion);
  const questions = useSelector((state) =>
    state.flow.questions.filter((item) => item.id !== currentQuestion.id)
  );
  const attributesOptions = (useSelector((state) => state.attributes) || []).map((attributes)=>(
    {
      value : attributes._id,
      label : attributes.displayName
    }
  ));

  
const conditionCases = [
    { label: 'is', value: 'is' },
    { label: "isn't", value: "isn't" },
    { label: 'has any value', value: 'has any value' },
    { label: 'contains', value: 'contains' },
    { label: "doesn't contain", value: "doesn't contain" },
    { label: 'begins with', value: 'begins with' },
    { label: 'is unknown', value: 'is unknown' }
  ];
  


  const dispatch = useDispatch();
  const language = useSelector(state => state.language.lang)
  const staticTexts = getStaticText(language);
  const nextQuestionOptions = questions.map((ques)=> (
    {
      label : getTextByLanguage(language,ques.labels),
      id : ques.id
    }
  ))
 
  const addOption = () => {
    let newCase = {
      id: uuid(),
      case : "",
      values : [
        { lang: 'en', value: '' },   // English
        { lang: 'hi', value: '' },   // Hindi
        { lang: 'zh', value: '' },   // Chinese
        { lang: 'ar', value: '' },   // Arabic
        { lang: 'ur', value: '' },   // Urdu
        { lang: 'pt', value: '' },   // Portuguese
        { lang: 'es', value: '' },   // Spanish
        { lang: 'fr', value: '' },   // French
        { lang: 'de', value: '' },   // German
        { lang: 'ru', value: '' }    // Russian
      ],
      next: {
        type: '',
        target: '',
      },
    };

    // Updating Active Question value in the store
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
         condition : {
            ...currentQuestion.condition,
            cases : [...currentQuestion.condition.cases,newCase]
         }
      },
    });
    // Updating values of the form array using react-hook form inbuilt-method
    methods.setValue('cases', [
      ...methods.getValues().cases,
      { ...newCase },
    ]);
  };

  const deleteOption = (option) => {
  
    let data = currentQuestion.condition.cases.filter((data) => data.id !== option.id);

     dispatch({ type: UPDATE_ACTIVE_QUESTION,  payload: {
        condition : {
           ...currentQuestion.condition,
           cases : data
        }
     } });
    // Updating values of links array using react-hook form inbuilt-method
     methods.setValue('cases', data);
  };

  const handleNextQuestion = (selectedQuestion, currentOptionId) => {

    let data = currentQuestion.condition.cases.map((option) => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id,
          },
        };
      } else {
        return option;
      }
    });
    let updatedCase = methods.getValues('cases').map((option) => {
      if (option.id === currentOptionId) {
        return {
          ...option,
          next: {
            type: selectedQuestion.type,
            target: selectedQuestion.id,
          },
        };
      } else {
        return option;
      }
    });
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        condition: {
          ...currentQuestion.condition,
          cases: data
        }
      }
    });
    methods.setValue('cases', updatedCase);
  };

  const showQuestionId = (id) => {
    if (id === 'end') {
      return 'End Chat';
    }
    if (id) {
      const question = questions
      .filter((question) => question.id === id)
      .map((data) => data.id);
      return question;
    }
    return 'Please choose one...';
  };

  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '20':
        return 20;
      case '1024':
        return 1024;
      default:
        return 99999;
    }
  };

  const getNextQuestion = (questions, nextQuestionId) => {
    if (Array.isArray(nextQuestionId)) {
      const nextQuestion = [...questions].find((ele) =>
        nextQuestionId.includes(ele.id)
      );
      if (nextQuestion) {
        return { label : getTextByLanguage( language, nextQuestion.labels)};
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }

    } else {
      if (nextQuestionId.toLowerCase() === 'end chat') {
        return { label: 'End chat' };
      } else {
        return { label: staticTexts['pleaseChooseOne'] };
      }
    }
  };

  const handleAttributesChange = (selectedAttribute) => {
      const payload = {
        ...currentQuestion,
        condition : {
         ...currentQuestion.condition,
         _attribute : selectedAttribute.value
        }
      }
      dispatch({ type: UPDATE_ACTIVE_QUESTION, payload });
      methods.setValue('condition._attribute', selectedAttribute.value);
  }

  const handleCaseChange = (selectedValue, id, index) => {
    const payload = {
      ...currentQuestion,
      condition: {
        ...currentQuestion.condition,
        cases: [
          ...currentQuestion.condition.cases.slice(0, index),
          {
            ...currentQuestion.condition.cases[index],
            case: selectedValue.label
          },
          ...currentQuestion.condition.cases.slice(index + 1)
        ]
      }
    };
    dispatch({ type: UPDATE_ACTIVE_QUESTION, payload });
    methods.setValue(`cases[${index}].case`, selectedValue.label);
  };
  
  return (
    <div style={{ paddingTop: 10 }}>
         <div className="custom-auto-complete">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={attributesOptions.find((attributes)=> attributes.value === currentQuestion?.condition?._attribute)?.label || staticTexts['pleaseChooseOne']}  
                  options={attributesOptions}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ''}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                         // '&:hover': { backgroundColor: '#E4DEF5' },
                          fontSize: 13,
                        }}
                      >
                       {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === 'selectOption') {
                      const payload =
                        value.label === 'End chat'
                          ? { type: 'question', id: 'end', label: 'End chat' }
                          : value;
                  handleAttributesChange(payload);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <>
                      <label>Select attribute</label>
                      <TextField
                        {...params}
                        // label={staticTexts['goToNextMessage']}
                      />
                    </>
                  )}
                  noOptionsText={
                    <span style={{ color: 'white',fontSize:'13px' }}>No matching options</span>
                  }
                />
              </div>
      {/* Cases for condition */}
      {currentQuestion.condition.cases.map((option, index) => {
        return (
          <React.Fragment key={index}>
            {/* For not manipulating the static options, inputs are hidden */}
            <input
              className="d-none"
              defaultValue={option.case}
              {...methods.register(`cases.${index}.case`)}
            />
            <input
              className="d-none"
              key={getTextByLanguage(language,option.values)}
              defaultValue={getTextByLanguage(language,option.values)}
              {...methods.register(`cases.${index}.value`)}
            />
            <input
              className="d-none"
              defaultValue={option.id}
              {...methods.register(`cases.${index}.id`)}
            />
            <input
              className="d-none"
              defaultValue={option.next.type}
              {...methods.register(`cases.${index}.next.type`)}
            />
            <input
              className="d-none"
              defaultValue={option.next.target}
              {...methods.register(`cases.${index}.next.target`)}
            />

            {/* End of hidden inputs */}
            <div className="single-choice-option-container mt-3">
              <div className="message-container mb-0"> 
              <div className='d-flex justify-content-between align-items-center'>
              <div className="custom-auto-complete mb-3" style={{width:'90%'}}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={conditionCases.find((cases)=> cases.value === option.case)?.label || staticTexts["pleaseChooseOne"]} 
                  options={conditionCases}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ''}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                         // '&:hover': { backgroundColor: '#E4DEF5' },
                          fontSize: 13,
                        }}
                      >
                       {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === 'selectOption') {
                      const payload =
                        value.label === 'End chat'
                          ? { type: 'question', id: 'end', label: 'End chat' }
                          : value;
                      handleCaseChange(payload,option._id,index);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <>
                      <label>Select condition</label>
                      <TextField
                        {...params}
                        // label={staticTexts['goToNextMessage']}
                      />
                    </>
                  )}
                  noOptionsText={
                    <span style={{ color: 'white',fontSize:'13px' }}>No matching options</span>
                  }
                />
              </div>

              {index === currentQuestion.condition.cases.length - 1 ? (
                    currentQuestion.condition.cases.length === 1 ? (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "10px", height: "40px" }}
                        role="button"
                      >
                        <img alt="plus" src={Plus} onClick={addOption} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: "10px", height: "40px" }}
                          role="button"
                        >
                          <img
                            alt="minus"
                            src={Minus}
                            onClick={() => deleteOption(option)}
                          />
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ marginLeft: "10px", height: "40px" }}
                          role="button"
                        >
                          <img alt="plus" src={Plus} onClick={addOption} />
                        </div>
                      </div>
                    )
                  ) : (
                    <div
                      className="d-flex align-items-center"
                      style={{ marginLeft: "10px", height: "40px" }}
                      role="button"
                    >
                      <img
                        alt="minus"
                        src={Minus}
                        onClick={() => deleteOption(option)}
                      />
                    </div>
                  )}
              </div>
    
                <label>Value</label>
                <div className="d-flex justify-content-between">
                  <div className="custom-input-box input-group mb-3">
                    <TextField
                      size="small"
                      key={getTextByLanguage(language,option.values)}
                      fullWidth
                      className="form-control theme-input size-14"
                      placeholder={"Type..."}
                      defaultValue={getTextByLanguage(language,option.values)}
                      inputProps={{ maxLength: getWhatsAppLimits("20") }}
                      // {...methods.register(`case.${index}.value`)}
                      {...methods.register(`cases.${index}.value`)}
                      InputProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="custom-auto-complete">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={getNextQuestion(
                    questions,
                    showQuestionId(option.next.target)
                  )}
                  options={[
                    ...nextQuestionOptions,
                    { label: "End chat", id: "end" },
                  ]}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ""}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                          // '&:hover': { backgroundColor: '#E4DEF5' },
                          fontSize: 13,
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      const payload =
                        value.label === "End chat"
                          ? { type: "question", id: "end", label: "End chat" }
                          : value;
                      handleNextQuestion(payload, option.id);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <>
                      <label>{staticTexts["goToNextMessage"]}</label>
                      <TextField
                        {...params}
                        // label={staticTexts['goToNextMessage']}
                      />
                    </>
                  )}
                  noOptionsText={
                    <span style={{ color: "white", fontSize: "13px" }}>
                      No matching options
                    </span>
                  }
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
