import { useState, useEffect } from 'react';
import './style.scss'
import { LOAD_FLOW, LOAD_NEW_QUESTION, UPDATE_FLOW, UPDATE_NON_BLOCK_LOADER } from '../../../../../redux/actions/actions';
import { createQuestion } from '../../../../../utilities/create-question';
import { addQuestionChecks } from '../../../../../utilities/add-question-checks';
import { useDispatch, useSelector } from 'react-redux';
import { BotMethods } from '../../../../../services/bot';
import { getTooltipText } from "../../../../../assets/json/component-tooltip/index";
import { getStaticText } from "../../../../../assets/json/component-static-text/index";
import { filterComponentByBot } from '../../../../../utilities/helper';
import { getFlowTexts } from '../../../../../assets/json/flowTexts';

export default function ChatComponent({ componentIndex, componentKey, value, loaderPosition }) {
    const actualflow = useSelector(state => state.flow);
    let flowQuestions = useSelector(state => state.flow.questions);
    const dispatch = useDispatch();
    const agency = useSelector(state => state.agency);
    const selectedQuestion = useSelector(state => state.activeQuestion);
    const [image, setImage] = useState();
    const language = useSelector((state)=> state.language.lang)
    const staticTexts = getStaticText(language);
    const tooltipTexts = getTooltipText();
    const botType = useSelector(state => state.currentBotDetails.botType);
    const { updateBot } = BotMethods();

    useEffect(() => {
        const fetchImage = async () => {
            try {
              //  const response = await import(`../../../../../assets/images-v2/${componentKey === 'AI' ? 'chatGPT' : componentKey}.png`) // change relative path to suit your needs
                const response = await import(`../../../../../assets/icons/${componentKey === 'AI' ? 'chatgpt' : componentKey === 'date' ? 'calendar' : componentKey === 'time' ? 'clock' : componentKey}.svg`) // change relative path to suit your needs
                setImage(response.default)
            } catch (err) {
                // setError(err)
            }
        }

        fetchImage()
    }, [componentKey]);


    const showComponentAnimation = (questionId) => {
        document.getElementById(questionId).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        document.getElementById(questionId).style.width = "0%";
        document.getElementById(questionId).style.overflow = "hidden";
        setTimeout(() => {
            document.getElementById(questionId).style.width = "100%";
        }, [1]);
        setTimeout(() => {
            document.getElementById(questionId).style.overflow = "inherit";

        }, [1000])
    }

    const addComponent = (type) => {
        componentIndex = loaderPosition === "top" ? componentIndex : componentIndex +1;
        
        let text = getFlowTexts(language)?.[type === 'AI' ? 'statement' : type];
      
        let que = createQuestion(type, text,language);
        que = addQuestionChecks(botType, type, que);

        function findQuestion(item) {
            return item.id === selectedQuestion.id;
          }
        let indexOfQuestion = flowQuestions.length ? flowQuestions.findIndex(findQuestion) : 0;
        const questionPayload = [...flowQuestions]
        questionPayload.splice(indexOfQuestion + 1, 0, que);
        const finalPayload = {
        ...actualflow,
        questions: questionPayload
        }

        dispatch({ type: LOAD_NEW_QUESTION, payload: { id: que.id } });
        dispatch({ type: LOAD_FLOW, payload: finalPayload });
        dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: true, index: componentIndex, loaderPosition } });
        updateBot({ questionData: que, action: 'add', index: indexOfQuestion })
            .then((response) => {
                showComponentAnimation(que.id);
                dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: false, index: 0, loaderPosition:"top" } });
                dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
            })
            .catch(error => {
                dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: false, index: 0, loaderPosition:"top" } });
                console.log(error);
            });

    }

    const matchTooltipKeys = (key) => {
        let value;
        if (key === "button") {
            value = "singleChoice"
        } else if (key === "contact") {
            value = "webLink"
        } else if (key === "multi_select") {
            value = "multipleChoice";
        } else if (key === "date") {
            value = "date";
        } else if (key === "smart_question") {
            value = "smartQuestion";
        } else if (key === "live_chat") {
            value = "liveChat";
        }else if (key === "AI") {
            value = "chatGPT";
        }
        else {
            value = key;
        }
        return value;
    }

    return (
        <>
            {
                filterComponentByBot(componentKey) &&
               
                    <li className='li-padding'>
                        <div className="d-flex li-item-container align-items-center" onClick={() => { addComponent(componentKey) }}>
                            <img style={{minWidth:"18px"}} width="18px" height="18px" src={image} alt="images" />
                            <div className='p-0'>
                                <div className="component-text li-item-head">{value} </div>
                                <div className="component-text li-item-caption">{tooltipTexts[matchTooltipKeys(componentKey)]} </div>
                            </div>
                        </div>
                    </li>
            }
        </>
    );
}