import englishTexts from './en.json';
import hindiTexts from './hi.json';
import spanishTexts from './es.json';
import germanTexts from './de.json';
import frenchTexts from './fr.json';
import italianTexts from './it.json';
import portgueseTexts from './pt.json';
import russianTexts from './ru.json';
import romanianTexts from './ro.json';
import dutchTexts from './nl.json';
import arabicTexts from './ar.json';
import turkishTexts from './tr.json';
import hebrewTexts from './he.json';

function getStaticText() {
  const language = !!localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
  if (language === 'es') {
    return spanishTexts;
  } else if (language === 'hi') {
    return hindiTexts;
  } else if (language === 'de') {
    return germanTexts;
  } else if (language === 'fr') {
    return frenchTexts;
  } else if (language === 'it') {
    return italianTexts;
  } else if (language === 'pt') {
    return portgueseTexts;
  } else if (language === 'ru') {
    return russianTexts;
  } else if (language === 'ro') {
    return romanianTexts;
  } else if (language === 'nl') {
    return dutchTexts;
  } else if (language === 'ar') {
    return arabicTexts;
  } else if (language === 'tr') {
    return turkishTexts;
  } else if (language === 'he') {
    return hebrewTexts;
  } else {
    return englishTexts;
  }
}

export { getStaticText }