import { combineReducers } from 'redux';
import { edgeReducer } from './edgeReducer';
import { flowReducer } from './flowReducer';
import { nodeReducer } from './nodeReducer';
import { loaderReducer } from './loaderReducer';
import { activeEdgeReducer } from './activeEdgeReducer';
import { editedEdgesReducer } from './editedEdgesReducer';
import { dialogueReducer } from './dialogueReducer';
import { activeQuestionReducer } from './activeQuestionReducer';
import { newQuestionReducer } from './newQuestionReducer';
import { connectedNodesReducer } from './connectedNodesReducer';
import { toastReducer } from './toastReducer';
import { loaderNonBlockReducer } from './loaderNonBlockReducer';
import { nodesPositionReducer } from './nodePositionsReducer';
import { agencyReducer } from './agencyReducer';
import { botReducer, currentBotDetailsReducer, getAllBotReducer } from './botReducer';
import { attributesReducer } from './attributesReducer';
import { allFlowReducer } from './allFlows';
import { langReducer } from './lang';
import { apiReducer } from './apiReducer';

const reducer = combineReducers({
  flow: flowReducer,
  edges: edgeReducer,
  nodes: nodeReducer,
  loader: loaderReducer,
  loaderNonBlock: loaderNonBlockReducer,
  activeEdge: activeEdgeReducer,
  editedEdges: editedEdgesReducer,
  activeQuestion: activeQuestionReducer,
  newQuestionAdded: newQuestionReducer,
  dialogues: dialogueReducer,
  connectedNodes: connectedNodesReducer,
  toast: toastReducer,
  nodePositions: nodesPositionReducer,  
  agency: agencyReducer,
  bot: botReducer,
  currentBotDetails: currentBotDetailsReducer,
  attributes: attributesReducer,
  language : langReducer,
  allFlows : allFlowReducer,
  allBots : getAllBotReducer,
  apis : apiReducer
});

export { reducer };
