import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: `Inter-Regular`,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "#000",
          color: "#fff",
          border : 'none'
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          background: "#000",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          background: "#000",
          color: "#fff",
          "&.Mui-focused  $notchedOutline": {
            border: "1px solid red !important", // Customize the border color on focus
          },
        },
        input: {
          "&.Mui-disabled": {
            opacity: 1,
            WebkitTextFillColor: "#fff",
          },
        },
        notchedOutline:{
          border: "1px solid rgba(255, 255, 255, 0.10) !important",
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
          background: "#000",
          border: "1px solid rgba(255, 255, 255, 0.10) !important",
        },
        popper:{
          marginTop:'3px !important'
        }
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#000 !important",
          border: "1px solid rgba(255, 255, 255, 0.10) ",
        },
        select: {
          background: "#000",
          borderRadius: "12px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#121212",
          color: "#fff",
          borderRadius:'12px',
          border: "1px solid #ffffff1a"
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          "&::before, &::after": {
            borderTop: "1px solid #4d4d4d",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#FFFFFF8C",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root : {
         transition : '0.3s ease-in-out !important'
        },
        modal : {
        transition : '0.3s ease-in-out !important'
        },
        paper: {
          borderRadius: '12px',
          margin: '0px',
          transition:  '0.3s ease-in-out !important',
          transformOrigin: 'center',
          border:'none',
          boxShadow:'none'
        },
        container: {
          background : "#121212"
        },
        backdrop : {
          transition : 'all 3s !important' 
        }
      },
    },
    MuiTypography:{
      styleOverrides:{
        root:{
          fontWeight: '600 !important'
        }
      }
    }
  },
});

export default theme;
