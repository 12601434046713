import { ADD_CONNECTED_NODES, CLEAR_CONNECTED_NODES, REMOVE_CONNECTED_NODES } from '../actions/actions';

export const initialState = [];

const connectedNodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONNECTED_NODES: {
      return [
        ...state,
        action.payload.id,
      ];
    }
    case REMOVE_CONNECTED_NODES: {
      return state.filter(d => d !== action.payload.id);
    }
    case CLEAR_CONNECTED_NODES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export { connectedNodesReducer };
