import { LOAD_NEW_QUESTION } from "../actions/actions";

export const initialState = {};

export const newQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NEW_QUESTION: {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
}