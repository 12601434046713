import Email from './Email';
import Image from './Image';
import './edit-component.css';
import { v4 as uuid } from 'uuid';
import LinkComponent from './Link';
import Catalogue from './Catalogue';
import RatingComponent from './Rating';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SingleChoice from './SingleChoice';
import Tooltip from '@mui/material/Tooltip';
import MultipleChoice from './MultipleChoice';
import { BotMethods } from '../../../services/bot';
import React, { useState, useEffect, Fragment } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  UPDATE_ACTIVE_QUESTION,
  UPDATE_FLOW,
  UPDATE_LOADER,
  UPDATE_DIALOGUE,
  UPDATE_TOAST,
} from '../../../redux/actions/actions';
import Document from './Document/document';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import AddressComponent from './Address';
import MenuItem from '@mui/material/MenuItem';
import { getStaticText } from '../../../assets/json/component-static-text/index';
import { getTooltipText } from '../../../assets/json/component-tooltip/index';
import InfoIcon from '../../../assets/images/info.svg';
import Autocomplete from '@mui/material/Autocomplete';
import ApiComponent from './api';
import FormHelperText from '@mui/material/FormHelperText';
import {
  createCases,
  createErrorMessage,
  createOptions,
  getBotOptions,
  getIcons,
  getKeysByBot,
  getLabelByKey,
  getTextByLanguage,
  updatePlaceholderText,
} from '../../../utilities/helper';
import { createLabel, updateLabel, updateLinks, updateTitle } from '../../../utilities/create-question';
import { keyboardTypes } from '../../../utilities/helper';
import Conditions from './Condition';
export default function EditComponent(props) {
  const { tab, language } = props;
  let timer = undefined;
  const methods = useForm();
  const flow = useSelector((state) => state.flow);
  const allFlows = useSelector((state)=> state.allFlows);
  const agency = useSelector((state) => state.agency);
  const [questions, setQuestionsList] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const questionData = useSelector((state) => state.activeQuestion);
  const [selectedBot,setSelectedBot] = useState(questionData?.tag?.botName ?? "");
  
  const acceptedValues = [
    { label: 'Image', id: 1 },
    { label: 'Video', id: 2 },
    { label: 'Document', id: 3 },
  ];
  const [nextQuestion, setNextQuestion] = useState({});
  const [nextFlow,setNextFlow] = useState({})
  const attributes = useSelector((state) => state.attributes) || [];
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;
  const botType = useSelector((state) => state.currentBotDetails.botType);
  const staticTexts = getStaticText(language);
  const allApis = useSelector((state) => state.apis)
  const tooltipTexts = getTooltipText();
  const { updateBot } = BotMethods();
  const [state, setState] = useState({
    slashPressed: false,
    apiList : []
  });

  const dispatch = useDispatch();
  const flowOptions = allFlows.map((flow)=>(
      {
        id : flow._id,
        label : flow.name
      }
  ));

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      apiList: allApis.map((apis) => ({
        id: apis._id,
        label: apis.name,
      })),
    }));
   let res = (state.apiList.find((apis) => apis.id === questionData.apiId)?.label) 
  }, []);

  const getErrorTitle = (type) => {
    switch (type) {
      case 'name': {
        return staticTexts[
          'errorMessageIfUserEntersMoreThanMaxAllowedCharacters'
        ];
      }
      case 'email': {
        return staticTexts['errorMessageIfUserEntersWrongEmail'];
      }
      case 'phone': {
        return staticTexts['errorMessageIfUserEntersWrongPhoneNumber'];
      }
      case 'file': {
        return staticTexts['errorMessageIfUserUploadsLargeFile'];
      }
      default: {
        return staticTexts['errorMessageIfUserEntersWrongInput'];
      }
    }
  };

  useEffect(() => {
    const subscription = watch(() => {
      if (!Object.keys(errors).length) {
        if (timer) clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(() => {
          handleSubmit(onSubmit)();
        }, 1000);
      }
    });

    return () => {
      if (timer) clearTimeout(timer);
      subscription.unsubscribe();
    };
  }, [handleSubmit, watch]);

  const onError = (errors) => {
    const error = errors[Object.keys(errors)[0]];
    dispatch({
      type: UPDATE_TOAST,
      payload: {
        show: true,
        type: 'error',
        description: error.message || 'Please fill all the required fields',
      },
    });
  };

  const onSubmit = (data) => {
    let updatedQuestion = { ...questionData };
    console.log('data',data);
    console.log('data2',updatedQuestion)
    updatedQuestion = flow.questions.find(
      (item) => item.id === questionData.id
    );
    
    if (data.label) {
      updatedQuestion = {
        ...updatedQuestion,
        labels: updateLabel(language, data.label),
      };
    }
    if (data.message) {
      updatedQuestion = {
        ...updatedQuestion,
        message: createErrorMessage(language, data.message, 'tooLengthy'),
      };
    }
    if (data.options) {
      updatedQuestion = {
        ...updatedQuestion,
        options: updatedQuestion.type === 'button' ? createOptions(language, data.options) : data.options,
      }
    }

    if (data.keyboardType) {
      updatedQuestion = {
        ...updatedQuestion,
        subType: {
          ...updatedQuestion.subType,
          keyboardType: data.keyboardType
        }
      }
    }
    if (data.placeholderText) {
      updatedQuestion = {
        ...updatedQuestion,
        subType: {
          ...updatedQuestion.subType,
          placeholderTexts: updatePlaceholderText(language, updatedQuestion.subType.placeholderTexts, data.placeholderText)
        }
      }
    }
    if (data.source) {
      updatedQuestion = {
        ...updatedQuestion,
        source: data.source
      }
    }
    if (data.next) {
      updatedQuestion = {
        ...updatedQuestion,
        next: data.next
      }
    }
    if (data.fileType){
      updatedQuestion = {
        ...updatedQuestion,
        subType: {
          ...updatedQuestion.subType,
          keyboardType: data.fileType
        }
      }
    }

    if(data.apiId){
      updatedQuestion = {
        ...updatedQuestion,
        apiId : data.apiId
      }
    }

    if(data.tag?._bot){
      updatedQuestion = {
        ...updatedQuestion,
        tag : {
          ...updatedQuestion.tag,
          _bot : data.tag._bot,
          botName : data.tag.botName
        }
      }
    }

    if(data.tag?.key){
      updatedQuestion = {
        ...updatedQuestion,
        tag : {
          ...updatedQuestion.tag,
          key : data.tag.key
        }
      }
    }

    if(data.condition?._attribute){
      updatedQuestion = {
        ...updatedQuestion,
        condition : {
          ...updatedQuestion.condition,
          _attribute : data.condition._attribute
        }
      }
    }

    if(data.cases){
      updatedQuestion = {
        ...updatedQuestion,
       condition : {
        ...updatedQuestion.condition,
        cases : createCases(language,data.cases)
       }
      }
    }

    if(data.links) {
      updatedQuestion = {
        ...updatedQuestion,
      links : updateLinks(language,data.links)
      } 
    }

    updatedQuestion = {
      ...updatedQuestion,
      previousResponseRequired: data.previousResponseRequired ? true : false
    };
    
    

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      // payload: { ...questionData, ...data },
      payload: updatedQuestion,
    });
    // dispatch({ type: UPDATE_LOADER, payload: true });
    updateBot({ questionData: updatedQuestion, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        });

        // setTimeout(() => {
        //   !omitHandlerDrawer.includes(questionData.type) && handleDrawer(false);
        // }, 500);
      })
      .catch((error) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'error',
            description:
              error.message ||
              'Could not update bot, Please try again later...',
          },
        });
      });
  };

  useEffect(() => {
    setQuestionsList(
      flow.questions.filter((que) => que.id !== questionData.id)
    );
    setFilteredQuestions(
      flow.questions
        .filter((que) => que.id !== questionData.id)
        .map((item) => ({ label: getTextByLanguage(language, item.labels), id: item.id, type: item.type }))
    );

    setNextQuestion(getQuestionById(questionData.next.target));
    if(questionData.type === 'redirect'){
      setNextFlow(getFlowById(questionData.next.target))
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, questionData,allFlows]);

  const handleQuestionChange = (e, question) => {
    setNextQuestion(question);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        next: {
          type: question.type,
          target: question.id,
        },
      },
    });

    methods.setValue('next', {
      type: question.type,
      target: question.id,
    });
  };
 
  const handleRedirectFlowChange = (e, question) => {
    setNextFlow(question);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        next: {
          type: 'flow',
          target: question.id,
        },
      },
    });

    methods.setValue('next', {
      type: 'flow',
      target: question.id,
    });
  };
  const getQuestionById = (id) => {
    if (id === 'end') {
      return { label: 'End chat' };
    }
    if (id) {
      return flow.questions.find((que) => que.id === id);
    }

    return {};
  };
  const getFlowById = (id) => {
    if (id === 'end') {
      return { label: 'End chat' };
    }
    if (id) {
      const {_id, name} = allFlows.find((que) => que._id === id);
      return {target : _id, type : name }
    }

    return {};
  };
  const handleCheckBoxChange = (e) => {
    methods.setValue(e.target.checked, e.target.checked);
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        ...questionData,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handlePreviousCheckboxChange = (e) => {
    methods.setValue('previousResponseRequired' , e.target.checked );
    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        ...questionData,
        previousResponseRequired : e.target.checked,
      },
    });
   
  };

  const handleNodeDelete = (e) => {
    dispatch({
      type: UPDATE_DIALOGUE,
      payload: { deleteNodeDialogue: true, deleteNodeData: props.data },
    });
  };

  const copyQuestion = () => {
    const newQuestion = {
      ...questionData,
      _id: undefined,
      id: uuid(),
      next: { target: '', type: '' },
    };

    let indexOfQuestion = flow.questions.length ? flow.questions.length : 0;
    dispatch({ type: UPDATE_LOADER, payload: true });
    updateBot({
      index: indexOfQuestion,
      questionData: newQuestion,
      action: 'copy',
    })
      .then((response) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Question copied successfully',
          },
        });

        setTimeout(() => {
          handleDrawer(false);
        }, 500);
      })
      .catch((error) => {
        dispatch({ type: UPDATE_LOADER, payload: false });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'error',
            description:
              error.message || 'Could not copy, Please try again later...',
          },
        });
      });
  };

  // const routeToTeam = () => {
  //   window.open("https://app.botpenguin.com/team", "_blank");
  // }

  const handleDrawer = (status) => {
    if (props.handleModal) {
      props.handleModal(false);
    }
  };

  const getWhatsAppLimits = (type) => {
    if (botType !== 'whatsapp') {
      return 99999;
    }

    switch (type) {
      case '1024':
        return 1024;
      case 'phone':
        return 1024;
      default:
        break;
    }
  };

  const addVariable = (key) => {
    const text = String(getTextByLanguage(language, questionData.labels))?.substring(
      0,
      String(questionData.labels).length - 1
    );
    const index = text.indexOf('/')
    const updatedText = text.substring(0, index) + key + text.substring(index + 1);
    
    methods.setValue('label', updatedText);
    setState((prev) => ({ ...prev, slashPressed: false }));
  };

  const handleKeyboardTypeChange = (_, type) => {
    const payload = {
      ...questionData,
      subType: {
        ...questionData.subType,
        keyboardType: type?.label ?? "",
        icon: type?.icon
      }
    };

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        subType: {
          ...questionData.subType,
          keyboardType: type?.label,
          icon: type?.icon
        },
      },
    });
    updateBot({ questionData: payload, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });

        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        })
      })

    //methods.setValue('keyboardType', value);
  }

  const handlefileTypeChange = (_, type) => {
    const payload = {
      ...questionData,
      subType: {
        ...questionData.subType,
        keyboardType : type.label
      }
    };

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        subType: {
          ...questionData.subType,
          keyboardType: type.label
        },
      },
    });
    updateBot({ questionData: payload, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });

        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        })
      })

    //methods.setValue('keyboardType', value);
  }

  const handleBotKeyChange = (_, details) => {
    const payload = {
      ...questionData,
     tag : {
      ...questionData.tag,
      key : details.key
     }
    };

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        tag: {
            ...questionData.tag,
            key : details.key
        },
      },
    });
    updateBot({ questionData: payload, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });

        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        })
      })

    methods.setValue('tag.key', details.key);
  }


  const handleApiChange = (_, details) => {
    const payload = {
      ...questionData,
     apiId : details.id
    };

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        apiId : details.id
      },
    });
    updateBot({ questionData: payload, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });

        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        })
      })

    methods.setValue('apiId', details.id);
  }

  const handleBotChange = (_,bot) => {
    const payload = {
      ...questionData,
     tag : {
      ...questionData.tag,
      _bot : bot._id,
      botName : bot.label
     }
    };

    dispatch({
      type: UPDATE_ACTIVE_QUESTION,
      payload: {
        tag: {
          ...questionData.tag,
          _bot : bot._id,
          botName : bot.label
        },
      },
    });
    updateBot({ questionData: payload, action: 'edit' })
      .then((response) => {
        // dispatch({ type: UPDATE_LOADER, payload: false });

        dispatch({
          type: UPDATE_FLOW,
          payload: response.questions
            ? response.questions
            : response.flows[0].questions,
        });
        dispatch({
          type: UPDATE_TOAST,
          payload: {
            show: true,
            type: 'success',
            description: 'Bot Updated Successfully',
          },
        })
      })
    methods.setValue('tag._bot', bot._id);
    methods.setValue('tag.botName',bot.label)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          style={{ overflow: "auto", paddingBottom: "50px" }}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="edit overflow-auto">
            {/* Top Bar */}
            {props.view !== "old" && (
              <div className="top-bar d-flex justify-content-between mb-4">
                <Tooltip title={tooltipTexts.close}>
                  <div
                    className="icon-background"
                    role="button"
                    style={{ background: "#DAE0FD" }}
                    onClick={() => handleDrawer(false)}
                  >
                    <span className="material-icons-outlined edit-component-icon">
                      clear
                    </span>
                  </div>
                </Tooltip>

                <div className="d-flex">
                  <Tooltip title={tooltipTexts.copyQuestion}>
                    <div
                      className="icon-background me-3"
                      style={{ background: "#DFE1E4" }}
                      role="button"
                      onClick={() => copyQuestion()}
                    >
                      <span className="material-icons text-secondary edit-component-icon">
                        content_copy
                      </span>
                    </div>
                  </Tooltip>

                  <Tooltip title={tooltipTexts.deleteQuestionFromFlow}>
                    <div
                      className="icon-background"
                      style={{ background: "#F9D9DC" }}
                      onClick={() => handleNodeDelete()}
                      role="button"
                    >
                      <span className="material-icons text-danger edit-component-icon">
                        delete
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}

            {/* Title */}
            {/* {
              props.view !== 'old' && <div className="title-container">
                <p className="title-content">Customize your message</p>
              </div>
            } */}

            {questionData.type === "date"
              ? tab === "customize" && (
                  <div className="custom-checkbox-container col-md-12 mb-3">
                    <FormControlLabel
                      className="custom-checkbox"
                      control={
                        <Checkbox
                          id="showTime"
                          size="small"
                          checked={questionData.showTime ? true : false}
                          {...register("showTime")}
                          name="showTime"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label={staticTexts["showTime"]}
                    />
                  </div>
                )
              : null}

            {/* Message */}
            <div
              className={`message-container ${
                (questionData.type === "template" &&
                  botType?.toLowerCase() === "whatsapp") ||
                questionData.type === "api"
                  ? "mb-0"
                  : "mt-2"
              }`}
            >
              {(questionData.type === "button" &&
                botType?.toLowerCase() === "whatsapp") ||
              (questionData.type === "template" &&
                botType?.toLowerCase() === "whatsapp") ||
              questionData.type === "api"
                ? null
                : tab === "customize" && (
                    <div className="custom-input-box input-group position-relative">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <label htmlFor="message-text">
                          {staticTexts["messageText"]}
                        </label>
                        <TextField
                          size="small"
                          fullWidth
                          multiline
                          placeholder={staticTexts.enterYourMessage}
                          defaultValue={getTextByLanguage(
                            language,
                            questionData.labels
                          )}
                          inputProps={{ maxLength: getWhatsAppLimits("1024") }}
                          className="form-control theme-textarea"
                          {...register("label", {
                            required: "Message is Required",
                          })}
                          onKeyUp={(e) => {
                            if (e.key === "/") {
                              setState((prev) => ({
                                ...prev,
                                slashPressed: true,
                              }));
                            } else {
                              setState((prev) => ({
                                ...prev,
                                slashPressed: false,
                              }));
                            }
                          }}
                          InputProps={{
                            className: "theme-input",
                            sx: {
                              fontSize: 14,
                              // padding: 0
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: 14,
                            },
                          }}
                        />
                        {botType === "whatsapp" && (
                          <FormHelperText id="my-helper-text" className="pt-2">
                            <div className="helper-text">
                              <img src={InfoIcon} alt="info" />{" "}
                              {staticTexts["textSlashText"]}
                            </div>
                          </FormHelperText>
                        )}
                      </div>
                      {state.slashPressed ? (
                        <div className="custom-dropdown slash-dropdown">
                          <ul
                            style={{
                              transform: "translate3d(1px, -50px, 0px)",
                              width: "100%",
                              maxHeight: 300,
                            }}
                          >
                            <div className="add-btn-section-head">
                              {staticTexts["defaultAttributes"]}
                            </div>

                            <div
                              className="attribute-name"
                              onClick={() => addVariable("{{name}}")}
                            >
                              {staticTexts["nameText"]}
                            </div>
                            <div
                              className="attribute-name"
                              onClick={() => addVariable("{{whatsappName}}")}
                            >
                              {staticTexts["whatsappName"]}
                            </div>
                            <div
                              className="attribute-name"
                              onClick={() => addVariable("{{email}}")}
                            >
                              {staticTexts["emailText"]}
                            </div>
                            <div
                              className="attribute-name"
                              onClick={() => addVariable("{{phone}}")}
                            >
                              {staticTexts["phoneNumberText"]}
                            </div>
                            <div
                              className="attribute-name"
                              onClick={() => addVariable("{{whatsAppNumber}}")}
                            >
                              {staticTexts["whatsappNumber"]}
                            </div>

                            {attributes?.length ? (
                              <div className="add-btn-section-head">
                                {staticTexts["customAttributes"]}
                              </div>
                            ) : null}
                            {attributes?.map((attribute, i) => {
                              return (
                                <div
                                  key={i}
                                  className="attribute-name"
                                  onClick={() =>
                                    addVariable(`{{${attribute.key}}}`)
                                  }
                                >
                                  {attribute.displayName}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  )}
            </div>

            {tab === "advance" && (
              <>
                {/* Live Chat */}
                {questionData.type === "live_chat" &&
                agency.type !== "WHITE_LABEL" ? (
                  <div className="mb-3" style={{ marginTop: "-10px" }}></div>
                ) : null}

                {/* Error Message on Wrong Input  */}
                {questionData.type === "phone" ||
                questionData.type === "email" ||
                // questionData.type === 'name' ||
                questionData.type === "number" ||
                questionData.type === "file" ? (
                  <>
                    <label>{getErrorTitle(questionData.type)}</label>
                    <div className="custom-input-box input-group mb-3">
                      <TextField
                        // label={getErrorTitle(questionData.type)}
                        size="small"
                        fullWidth
                        defaultValue={getTextByLanguage(
                          language,
                          questionData.message
                        )}
                        placeholder={
                          questionData.type === "file"
                            ? staticTexts["maxUploadSizeIs10Mb"]
                            : staticTexts["enterErrorMessageOnWrongInput"]
                        }
                        className="form-control size-14 theme-input"
                        {...register("message.error")}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {questionData.type === "email" && botType === "website" ? (
                  <Email data={props.data} />
                ) : null}
              </>
            )}

            {tab === "customize" && (
              <>
                {/* Document */}
                {questionData.type === "document" ? (
                  <Document data={props.data} />
                ) : null}

                {/* Catalogue */}
                {questionData.type === "catalogue" &&
                botType?.toLowerCase() === "whatsapp" ? (
                  <Catalogue />
                ) : null}

                {/* Link */}
                {questionData.type === "contact" ? (
                  <LinkComponent data={props.data} />
                ) : null}

                {/* Image */}
                {questionData.type === "image" ? (
                  <Image data={props.data} />
                ) : null}

                {/* Rating */}
                {questionData.type === "rating" ? (
                  <RatingComponent data={props.data} />
                ) : null}

                {/* Single Choice */}
                {questionData.type === "button" ? (
                  <SingleChoice data={props.data} />
                ) : null}

                {/* Condition */}
                {questionData.type === "condition" ? (
                  <Conditions data={props.data} />
                ) : null}

                {/* Multiple Choice */}
                {questionData.type === "multi_select" ? (
                  <MultipleChoice data={props.data} />
                ) : null}

                {/** Video */}
                {questionData.type === "video" ? (
                  <>
                    <label>{staticTexts["youTubeLink"]}</label>
                    <div className="custom-input-box input-group my-3">
                      <TextField
                        size="small"
                        fullWidth
                        className="form-control size-14 theme-input"
                        defaultValue={questionData.source}
                        placeholder="https://example.com"
                        {...register("source", {
                          required: "Video url is required",
                        })}
                        InputProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {/* Address Component */}
                {questionData.type === "address" &&
                botType?.toLowerCase() === "whatsapp" ? (
                  <AddressComponent />
                ) : null}

                {/* Chat GPT */}
                {questionData.type === "AI" ? (
                  <div className="custom-input-box input-group my-3">
                    <TextField
                      label={
                        ["required", "min", "max"].includes(
                          errors?.queriesToHandle?.type
                        )
                          ? "Please enter a valid value"
                          : staticTexts["queriesToHandle"]
                      }
                      error={["required", "min", "max"].includes(
                        errors?.queriesToHandle?.type
                      )}
                      size="small"
                      fullWidth
                      type="number"
                      className="form-control size-14 theme-input"
                      defaultValue={questionData.queriesToHandle}
                      placeholder="10"
                      {...register("queriesToHandle", {
                        required: true,
                        min: 1,
                        max: 999999,
                      })}
                      InputProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  </div>
                ) : null}
              </>
            )}

            {/* API  */}
            {tab === "customize" && questionData.type === "api" && (
              <ApiComponent questionData={questionData} />
            )}

            {tab === "advance" && (
              <>
                {/* Advanced Options */}
                <div className="message-container">
                  <div className="d-flex align-items-center">
                    <h6 className="delay-text" style={{ color: "#626262" }}>
                      {staticTexts["addDelayOf"]}
                    </h6>
                    <div
                      className="icon-background mx-2"
                      style={{
                        background: "#F3F4F5",
                        border: "1px solid #d7d7d7",
                        marginRight: "10px",
                        paddingTop: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="number"
                        defaultValue={questionData.delay}
                        onKeyUp={(e) => {
                          const limit = 50;
                          if (e.target.value > limit)
                            methods.setValue("delay", limit);
                        }}
                        className="edit-component-input"
                        {...register("delay")}
                      />
                    </div>
                    <h6 className="delay-text" style={{ color: "#626262" }}>
                      {staticTexts["secondsBeforeThisMessageDisplayed"]}.
                    </h6>
                  </div>
                </div>
              </>
            )}

            {tab === "customize" &&
            questionData.type !== "button" &&
            questionData.type !== "smart_question" &&
            questionData.type !== "redirect" &&
            questionData.type !== "condition" &&
            questionData.type !== "api" ? (
              <div className="custom-auto-complete mt-4">
                <label htmlFor="next-message">
                  {staticTexts["goToNextMessage"]}
                </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    questions.filter((ele) => ele.id === nextQuestion?.id)
                      .length
                      ? getTextByLanguage(language, nextQuestion.labels)
                      : "End chat" === nextQuestion?.label
                      ? nextQuestion.label
                      : staticTexts["pleaseChooseOne"]
                  }
                  options={[...filteredQuestions, { label: "End chat" }]}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ""}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                          "&:hover": { backgroundColor: "#121212" },
                          fontSize: 13,
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      const payload =
                        value.label === "End chat"
                          ? { type: "question", id: "end", label: "End chat" }
                          : value;
                      handleQuestionChange(e, payload);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}

                      // label={staticTexts['goToNextMessage']}
                    />
                  )}
                />
              </div>
            ) : null}

            {/* Redirect */}
            {tab === "customize" && questionData.type === "redirect" ? (
              <div className="custom-auto-complete mt-4">
                <label htmlFor="next-message">
                  {staticTexts["goToNextFlow"]}
                </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    allFlows.filter((ele) => ele._id === nextFlow?.target)
                      .length
                      ? nextFlow.type
                      : staticTexts["pleaseChooseOne"]
                  }
                  options={[...flowOptions]}
                  renderOption={(props, option) => (
                    <Tooltip
                      title={option.label?.length > 40 ? option.label : ""}
                      followCursor
                    >
                      <MenuItem
                        {...props}
                        sx={{
                          "&:hover": { backgroundColor: "#121212" },
                          fontSize: 13,
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    </Tooltip>
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      handleRedirectFlowChange(e, value);
                    }
                  }}
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}

                      // label={staticTexts['goToNextMessage']}
                    />
                  )}
                />
              </div>
            ) : null}
            {/* Subtypes */}
            {tab === "advance" && questionData.type === "button" ? (
              <Fragment>
                <div className="custom-auto-complete mt-4">
                  <label htmlFor="next-message">
                    {staticTexts["chooseKeyboardType"]}
                  </label>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: "90%" }}
                      defaultValue={questionData.subType.keyboardType}
                      options={keyboardTypes}
                      {...register("keyboardType")}
                      renderOption={(props, option) => (
                        <Tooltip
                          title={option.label?.length > 40 ? option.label : ""}
                          followCursor
                        >
                          <MenuItem
                            {...props}
                            sx={{
                              //  '&:hover': { backgroundColor: '#E4DEF5' },
                              fontSize: 13,
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        </Tooltip>
                      )}
                      onChange={handleKeyboardTypeChange}
                      // sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}

                          // label={staticTexts['goToNextMessage']}
                        />
                      )}
                    />
                    {questionData.subType.icon && (
                      <img
                        src={getIcons(questionData.subType.icon)}
                        alt="keyboard-icon"
                      />
                    )}
                  </div>
                </div>
                {/* <div className="custom-auto-complete mt-4">
                  <label htmlFor="next-message">
                    {staticTexts['chooseIcon']}
                  </label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    defaultValue={questionData.subType.icon}
                    options={icons}
                   {...register('icon')}
                    renderOption={(props, option) => (
                      <Tooltip
                        title={option.label?.length > 40 ? option.label : ''}
                        followCursor
                      >
                        <MenuItem
                          {...props}
                          sx={{
                            '&:hover': { backgroundColor: '#E4DEF5' },
                            fontSize: 13,
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      </Tooltip>
                    )}
                    // sx={{ width: "100%" }}
                    onChange={handleIconChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}

                        // label={staticTexts['goToNextMessage']}
                      />
                    )}
                  />
                </div> */}
                <div className="custom-auto-complete mt-4">
                  <label htmlFor="next-message">
                    {staticTexts["placeHolderText"]}
                  </label>
                  <TextField
                    className="form-control theme-textarea"
                    size="small"
                    sx={{ fontSize: "13px" }}
                    placeholder="Type placeholder here"
                    defaultValue={getTextByLanguage(
                      language,
                      questionData.subType.placeholderTexts
                    )}
                    {...register("placeholderText")}
                  />
                </div>
              </Fragment>
            ) : null}
            {questionData.type === "file" && tab === "advance" && (
              <>
                <div className="custom-auto-complete mt-4">
                  <label>Accept</label>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: "90%" }}
                      defaultValue={questionData.subType.keyboardType}
                      options={acceptedValues}
                      {...register("fileType")}
                      renderOption={(props, option) => (
                        <Tooltip
                          title={option.label?.length > 40 ? option.label : ""}
                          followCursor
                        >
                          <MenuItem
                            {...props}
                            sx={{
                              "&:hover": { backgroundColor: "#E4DEF5" },
                              fontSize: 13,
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        </Tooltip>
                      )}
                      onChange={handlefileTypeChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </div>
              </>
            )}

            {tab === "customize" && questionData.type === "AI" && (
              <div className="mt-4">
                <Button
                  style={{
                    backgroundColor: "#5371FF",
                    borderRadius: "10px",
                    textTransform: "none",
                    width: "100%",
                  }}
                  variant="contained"
                  onClick={() => {
                    window.parent.postMessage("trainAI", "*");
                  }}
                >
                  {staticTexts["trainAIBot"]}
                </Button>
              </div>
            )}

            {tab === "customize" && questionData.type === "live_chat" && (
              <div className="mt-4">
                <Button
                  style={{ backgroundColor: "#226CF4", textTransform: "none" }}
                  variant="contained"
                  onClick={() => {
                    window.parent.postMessage("manageTeam", "*");
                  }}
                >
                  {staticTexts["manageTeam"]}
                </Button>
              </div>
            )}

            {tab === "advance" && (
              <Fragment>
                <div className="custom-auto-complete mt-4">
                  <label htmlFor="next-message">Select bot</label>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: "90%" }}
                      defaultValue={
                        questionData?.tag?.botName ??
                        staticTexts["pleaseChooseOne"]
                      }
                      options={getBotOptions()}
                      renderOption={(props, option) => (
                        <Tooltip
                          title={option.label?.length > 40 ? option.label : ""}
                          followCursor
                        >
                          <MenuItem
                            {...props}
                            sx={{
                              //  '&:hover': { backgroundColor: '#E4DEF5' },
                              fontSize: 13,
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        </Tooltip>
                      )}
                      onChange={handleBotChange}
                      // sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}

                          // label={staticTexts['goToNextMessage']}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="custom-auto-complete mt-4">
                  <label htmlFor="next-message">Select key</label>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: "90%" }}
                      defaultValue={
                        getLabelByKey(questionData?.tag?.key) ??
                        staticTexts["pleaseChooseOne"]
                      }
                      options={getKeysByBot(selectedBot)}
                      {...register("key")}
                      renderOption={(props, option) => (
                        <Tooltip
                          title={option.label?.length > 40 ? option.label : ""}
                          followCursor
                        >
                          <MenuItem
                            {...props}
                            sx={{
                              //  '&:hover': { backgroundColor: '#E4DEF5' },
                              fontSize: 13,
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        </Tooltip>
                      )}
                      onChange={handleBotKeyChange}
                      // sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label={staticTexts['goToNextMessage']}
                        />
                      )}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            { ['button', 'question', 'statement'].includes(questionData.type)
              ? tab === "advance" && (
                  <Fragment>
                    <div className="custom-checkbox-container col-md-12 mt-3">
                      <FormControlLabel
                        className="custom-checkbox"
                        control={
                          <Checkbox
                            id="previousResponseRequired"
                            size="small"
                            checked={ questionData?.previousResponseRequired }
                            onChange={handlePreviousCheckboxChange}
                            name="previousResponseRequired"
                          />
                        }
                        label={"Previous response required"}
                      />
                    </div>
                    {questionData.previousResponseRequired && (
                      <div className="custom-auto-complete mt-4">
                        <label htmlFor="next-message">Choose api</label>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Autocomplete
                            disablePortal
                            key={ state.apiList.find((apis) => apis.id === questionData.apiId)?.label}
                            id="combo-box-demo"
                            sx={{ width: "90%" }}
                            defaultValue={
                              state.apiList.find((apis) => apis.id === questionData.apiId)?.label || staticTexts['pleaseChooseOne']
                            }
                            
                            options={state.apiList}
                            {...register("apiId")}
                            renderOption={(props, option) => (
                              <Tooltip
                                title={
                                  option.label?.length > 40 ? option.label : ""
                                }
                                followCursor
                              >
                                <MenuItem
                                  {...props}
                                  sx={{
                                    //  '&:hover': { backgroundColor: '#E4DEF5' },
                                    fontSize: 13,
                                  }}
                                >
                                  {option.label}
                                </MenuItem>
                              </Tooltip>
                            )}
                           onChange={handleApiChange}
                            // sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={staticTexts['goToNextMessage']}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                )
              : null}
            {/* Buttons */}
            <div
              className="d-flex message-conatiner"
              style={{ marginTop: "30px" }}
            >
              {/* <button ref={submitButton} id="submitButton" type="submit" className="btn btn-primary theme-button" style={{ marginRight: "15px" }}>Save Changes </button> */}
              {/* <button className="btn theme-button" style={{ background: "#DFE1E4" }}><span style={{ color: "#333333" }}>Use Default</span></button> */}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
