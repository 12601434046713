import { LOAD_ATTRIBUTES } from '../actions/actions';

export const initialState = [];

const attributesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ATTRIBUTES: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

export { attributesReducer };