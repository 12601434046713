import { useState } from 'react';
import './index.scss'
import multi_select from '../../../assets/images-v2/multi_select.png';
import date from '../../../assets/images-v2/date.png';
//import contact from '../../../assets/images-v2/contact.png';
//import rating from '../../../assets/images-v2/rating.png';
//import range from '../../../assets/images-v2/range.png';
//import video from '../../../assets/images-v2/video.png';
import live_chat from '../../../assets/images-v2/live_chat.png';
//import redirect from '../../../assets/images/chat-components/redirect.svg';
//import single_product from '../../../assets/images-v2/single_product.svg';
//import multi_product from '../../../assets/images-v2/multi_product.svg';
//import template from '../../../assets/images-v2/template.png';

import { LOAD_FLOW, LOAD_NEW_QUESTION, UPDATE_FLOW, UPDATE_NON_BLOCK_LOADER } from '../../../redux/actions/actions';
import { createQuestion } from '../../../utilities/create-question';
import { addQuestionChecks } from '../../../utilities/add-question-checks';
import { useDispatch, useSelector } from 'react-redux';
import { BotMethods } from '../../../services/bot';
import { Tooltip } from "@mui/material";
import { filterComponentByBot } from '../../../utilities/helper';
import { getTooltipText } from "../../../assets/json/component-tooltip/index";
import { getStaticText } from "../../../assets/json/component-static-text/index";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ArrowDropDown as ExpandMoreIcon } from '@mui/icons-material';
import { UseSelector } from 'react-redux/es/hooks/useSelector';


import {chatgptIcon,messageIcon,image,thumbsUpIcon,emailIcon,textIcon,phoneNumber,personIcon,document as file/*,catalogue*/,api,address,location,numericInput as number, calendar, clock, document,video,redirect,contact} from "../../../assets/icons/index"
import { getFlowTexts } from '../../../assets/json/flowTexts';

export default function ComponentV1(props) {
  const actualflow = useSelector(state => state.flow);
  let flowQuestions = useSelector(state => state.flow.questions);
  const selectedQuestion = useSelector(state => state.activeQuestion);
  const botType = useSelector(state => state.currentBotDetails.botType);
  const dispatch = useDispatch();
  const agency = useSelector(state => state.agency);
  const [expand, setExpand] = useState("frequently-used");
  const tooltipTexts = getTooltipText();
  const { updateBot } = BotMethods();
  const language = useSelector((state)=> state.language.lang);
  const staticTexts = getStaticText(language);
  const flowTexts = getFlowTexts(language)
  

  const showComponentAnimation = (questionId) => {
    const questionElement = window.document.getElementById(questionId);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      questionElement.style.width = "0%";
      questionElement.style.overflow = "hidden";
      setTimeout(() => {
        questionElement.style.width = "100%";
      }, [1]);
      setTimeout(() => {
        questionElement.style.overflow = "inherit";

      }, [1])
    }
  }

  const addComponent = (type) => {
    let text = flowTexts[type === 'AI' ? 'statement' : type];
   
    let que = createQuestion(type, text,language);
    que = addQuestionChecks(botType, type, que);

    que.sendNotifications = true;

    function findQuestion(item) {
      return item.id === selectedQuestion.id;
    }
    let indexOfQuestion = flowQuestions.length ? (flowQuestions).findIndex(findQuestion) : 0 ;
    const questionPayload = [...flowQuestions]
    questionPayload.splice(indexOfQuestion + 1, 0, que);
    const finalPayload = {
      ...actualflow,
      questions: questionPayload
    }

    dispatch({ type: LOAD_NEW_QUESTION, payload: { id: que.id } });
    dispatch({ type: LOAD_FLOW, payload: finalPayload });
    dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: true, index: indexOfQuestion + 1, loaderPosition: "top" } });

    setTimeout(() => {
      showComponentAnimation(que.id);

    }, 100)

    updateBot({ questionData: que, action: 'add', index: indexOfQuestion })
      .then((response) => {
        // showComponentAnimation(que.id);
        dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: false, index: 0, loaderPosition: "top" } });
        dispatch({ type: UPDATE_FLOW, payload: response.questions ? response.questions : response.flows[0].questions });
      })
      .catch(() => {
        dispatch({ type: UPDATE_NON_BLOCK_LOADER, payload: { isLoading: true, index: 0, loaderPosition: "top" } });
      });
  }

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpand(isExpanded ? panel : false);
    };

  return (
    <>

      <div className="title-v1">
        {staticTexts['addChatComponent']}
      </div>

      <div className='v1-components-container px-1 container-fluid '>
        <div className='scroll-content' style={{ marginTop: "52px", paddingRight: "5px", paddingLeft: "5px" }}>
          <ul className='flow-list'>
            <Accordion disableGutters elevation={2} expanded={expand === "request-information"} onChange={handleChange("request-information")}>
              <AccordionSummary
                className='accordion-summary'
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  ...(expand === "request-information" && { backgroundColor: "rgba(0, 0, 0, 0.06)!important" })
                }}
              >
                <Typography variant='body2'>{staticTexts['requestInformation']}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "5px 0px" }}>
                {
                  filterComponentByBot('name') &&
                  <Tooltip title={tooltipTexts.name} placement="right">
                    <li>
                      <div className=" d-flex" onClick={() => { addComponent('name'); }}>
                        <img className="flow-img-v1" src={personIcon} alt="images"></img>
                        <span className="components-old ">{staticTexts['nameText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('phone') &&
                  <Tooltip title={tooltipTexts.phone} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('phone'); }}>
                        <img className="flow-img-v1" src={phoneNumber} alt="images"></img>
                        <span className="components-old ">{staticTexts['phoneNumberText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('email') &&
                  <Tooltip title={tooltipTexts.email} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('email'); }}>
                        <img className="flow-img-v1" src={emailIcon} alt="images"></img>
                        <span className="components-old ">{staticTexts['emailText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('button') &&
                  <Tooltip title={tooltipTexts.singleChoice} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('button'); }}>
                        <img className="flow-img-v1" src={thumbsUpIcon} alt="images"></img>
                        <span className="components-old ">{staticTexts['singleChoiceText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('question') &&
                  <Tooltip title={tooltipTexts.question} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('question'); }}>
                        <img className="flow-img-v1" src={textIcon} alt="images"></img>
                        <span className="components-old ">{staticTexts['textQuestionsText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('file') &&
                  <Tooltip title={tooltipTexts.file} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('file'); }}>
                        <img className="flow-img-v1" src={file} alt="images"></img>
                        <span className="components-old ">{staticTexts['fileText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('location') &&
                  <Tooltip title={tooltipTexts.location} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('location'); }}>
                        <img className="flow-img-v1" src={location} alt="images"></img>
                        <span className="components-old ">{staticTexts['locationText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('date') &&
                  <Tooltip title={tooltipTexts.date} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('date'); }}>
                        <img className="flow-img-v1" src={calendar} alt="images"></img>
                        <span className="components-old ">Date </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                 {
                  filterComponentByBot('date') &&
                  <Tooltip title={tooltipTexts.time} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('time'); }}>
                        <img className="flow-img-v1" src={clock} alt="images"></img>
                        <span className="components-old ">Time</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('number') &&
                  <Tooltip title={tooltipTexts.number} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('number'); }}>
                        <img className="flow-img-v1" src={number} alt="images"></img>
                        <span className="components-old ">{staticTexts['numericInputText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {/* {
                  filterComponentByBot('smart_question') &&
                  <Tooltip title={tooltipTexts.smartQuestion} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('smart_question'); }}>
                        <img className="flow-img-v1" src={smart_question} alt="images"></img>
                        <span className="components-old ">{staticTexts['smartQuestionText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                } */}
                {
                  filterComponentByBot('redirect') && <li>
                    <div className="d-flex" onClick={() => { addComponent('redirect'); }}>
                      <img className="flow-img-v1" src={redirect} alt="images"></img>
                      <span className="components-old ">{staticTexts['redirectText']} </span>
                    </div>
                  </li>
                }
                {
                  filterComponentByBot('document') &&
                  <Tooltip title={tooltipTexts.document} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('document'); }}>
                        <img className="flow-img-v1" src={file} alt="images"></img>
                        <span className="components-old ">{staticTexts['documentText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('address') &&
                  <Tooltip title={tooltipTexts.address} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('address') }}>
                        <img className="flow-img-v1" src={address} alt="images"></img>
                        <span className="components-old ">{staticTexts['addressText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('api') &&
                  <Tooltip title={tooltipTexts['api']} placement="right">
                    <li>
                      <div className="d-fle position-relative" onClick={() => { addComponent('api') }}>
                        <img className="flow-img-v1" src={api} alt="images"></img>
                        <span className="components-old ">{staticTexts['apiText']}</span>
                      </div>
                    </li>
                  </Tooltip>
                }
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters elevation={2} expanded={expand === "send-information"} onChange={handleChange("send-information")}>
              <AccordionSummary
                className='accordion-summary'
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  ...(expand === "send-information" && { backgroundColor: "rgba(0, 0, 0, 0.06)!important" })
                }}
              >
                <Typography variant='body2'>{staticTexts['sendInformation']}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "5px 0px" }}>
                {
                  filterComponentByBot('statement') &&
                  <Tooltip title={tooltipTexts.statement} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('statement'); }}>
                        <img className="flow-img-v1" src={messageIcon} alt="images"></img>
                        <span className="components-old ">{staticTexts['messageText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('image') &&
                  <Tooltip title={tooltipTexts.image} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('image'); }}>
                        <img className="flow-img-v1" src={image} alt="images"></img>
                        <span className="components-old ">{staticTexts['imageOrGifText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('video') &&
                  <Tooltip title={tooltipTexts.video} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('video'); }}>
                        <img className="flow-img-v1" src={video} alt="images"></img>
                        <span className="components-old ">{staticTexts['videoText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                 {
                  <Tooltip title={tooltipTexts.document} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('document'); }}>
                        <img className="flow-img-v1" src={document} alt="docs"></img>
                        <span className="components-old ">Document</span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {
                  filterComponentByBot('contact') &&
                  <Tooltip title={tooltipTexts.webLink} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('contact'); }}>
                        <img className="flow-img-v1" src={contact} alt="images"></img>
                        <span className="components-old ">{staticTexts['webLinkText']} </span>
                      </div>
                    </li>
                  </Tooltip>
                }
                {/* {
                  filterComponentByBot('single_product') &&
                  <Tooltip title={tooltipTexts.single_product} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('single_product') }}>
                        <img className="flow-img-v1" src={single_product} alt="images"></img>
                        <span className="components-old ">{staticTexts['single_product_text']}</span>
                      </div>
                    </li>
                  </Tooltip>
                } */}
                {/* {
                  filterComponentByBot('multi_product') &&
                  <Tooltip title={tooltipTexts.multi_product} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('multi_product') }}>
                        <img className="flow-img-v1" src={multi_product} alt="images"></img>
                        <span className="components-old ">{staticTexts['multi_product_text']}</span>
                      </div>
                    </li>
                  </Tooltip>
                } */}
                {/* {
                  filterComponentByBot('template') &&
                  <Tooltip title={tooltipTexts['template']} placement="right">
                    <li>
                      <div className="d-flex" onClick={() => { addComponent('template') }}>
                        <img className="flow-img-v1" src={template} alt="images"></img>
                        <span className="components-old ">{staticTexts['template_text']}</span>
                      </div>
                    </li>
                  </Tooltip>
                } */}
              </AccordionDetails>
            </Accordion>

            {
              (filterComponentByBot('live_chat') || filterComponentByBot('AI')) &&
              <Accordion disableGutters elevation={2} expanded={expand === "trigger-action"} onChange={handleChange("trigger-action")}>
                <AccordionSummary
                  className='accordion-summary'
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    ...(expand === "trigger-action" && { backgroundColor: "rgba(0, 0, 0, 0.06)!important" })
                  }}
                >
                  <Typography variant='body2'>{staticTexts['triggerActions']}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "5px 0px" }}>
                  {/* {
                    filterComponentByBot('live_chat') &&
                    <Tooltip title={tooltipTexts.liveChat} leaveDelay={200} placement="right">
                      <li>
                        <div className="d-flex position-relative" onClick={() => { addComponent('live_chat'); }}>
                          <img className="flow-img-v1" src={live_chat} alt="images"></img>
                          <span className="components-old ">{staticTexts['liveChatText']} </span>
                        </div>
                      </li>
                    </Tooltip>
                  } */}
                  {
                    filterComponentByBot('AI') &&
                    <Tooltip title={tooltipTexts.chatGPT} placement="right">
                      <li>
                        <div className="d-flex position-relative" onClick={() => { addComponent('AI'); }}>
                          <img className="flow-img-v1" src={chatgptIcon} alt="images"></img>
                          <span className="components-old ">{staticTexts['chatGPT']}</span>
                        </div>
                      </li>
                    </Tooltip>
                  }
                   {
                    <Tooltip title={tooltipTexts.chatGPT} placement="right">
                      <li>
                        <div className="d-flex position-relative" onClick={() => { addComponent('redirect'); }}>
                          <img className="flow-img-v1" src={redirect} alt="images"></img>
                          <span className="components-old ">Redirect</span>
                        </div>
                      </li>
                    </Tooltip>
                  }
                   {
                    <Tooltip title={tooltipTexts.chatGPT} placement="right">
                      <li>
                        <div className="d-flex position-relative" onClick={() => { addComponent('condition'); }}>
                          <img className="flow-img-v1" src={redirect} alt="images"></img>
                          <span className="components-old ">Conditions</span>
                        </div>
                      </li>
                    </Tooltip>
                  }
                </AccordionDetails>
              </Accordion>
            }

          </ul>
        </div>
      </div>
    </>
  );

};