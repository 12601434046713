import './image.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LOADER } from '../../../../redux/actions/actions';
import { uploadImage } from '../../../../services/upload';
import { UPDATE_ACTIVE_QUESTION, UPDATE_TOAST } from '../../../../redux/actions/actions';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import CloseIcon from "../../../../assets/images/close.svg";
import ImageIcon from "../../../../assets/images/chat-components/image.svg";
import InfoIcon from "../../../../assets/images/info.svg";
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import FormHelperText from "@mui/material/FormHelperText";

export default function Image(props) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(props.data.source);
  const botType = useSelector(state => state.currentBotDetails.botType);
  const agency = useSelector(state => state.agency);
  const language = useSelector(state => state.language.lang)
  const staticTexts = getStaticText(language);
  const attributes = useSelector(state => state.attributes) || [];
  const [state, setState] = useState({
    slashPressed: false
  })

  const uploadSelectedImage = (e) => {
    const formData = new FormData();
    formData.append('id', props.data.id);
    formData.append('type', 'image');
    formData.append('file', e.target.files[0], e.target.files[0].name);

    const uploadFile = e.target.files[0];
    if (uploadFile.size / 1024 / 1024 <= 20) {
      dispatch({ type: UPDATE_LOADER, payload: true })
      uploadImage(formData)
        .then((response) => {
          setTimeout(() => {
            setSelectedImage(response.data);
            methods.setValue('source', response.data);

            dispatch({ type: UPDATE_ACTIVE_QUESTION, payload: { source: response.data } });
            dispatch({ type: UPDATE_LOADER, payload: false });
          }, 500)
        })
        .catch(error => {
          dispatch({ type: UPDATE_LOADER, payload: false });
          console.log(error);
        })
    } else {
      dispatch({
        type: UPDATE_TOAST,
        payload: { show: true, type: 'error', description: 'Image should be less than 20MB' }
      });
    }
  }

  const removeImage = () => {
    setSelectedImage("");
    methods.setValue('source', "");
  }

  const addVariable = (key) => {
    const text = String(props.data.source)?.substring(0, String(props.data.source).length - 1);
    const index = text.indexOf('/')
    const updatedText = text.substring(0, index) + key + text.substring(index + 1);
    methods.setValue("source", updatedText);
    setState(prev => ({ ...prev, slashPressed: false }));
  }

  return (
    <>
      {/* <div className="d-flex">
        <button className="btn btn-outline-primary me-3 theme-button" onClick={(e) => openGifContainer(e, false)}>Single Image</button>
        <button className="btn btn-outline-primary " style={{ padding: '0 30px' }} onClick={(e) => openGifContainer(e, true)}>GIF</button>
      </div> */}

      <Divider className='mb-3'>OR</Divider>

      {/* </>
        :
        <> */}
      <div
        style={{
          border: '1px dashed rgb(17, 140, 252)',
          width: '100%',
          textAlign: 'center',
          padding: '15px',
          marginBottom: '18px',
          borderRadius: '12px',
        }}
        className="d-flex flex-column">
        {selectedImage
          ?
          <>
            <div className='d-flex justify-content-between '>
              <div
                style={{
                  width: '100%',
                  height: '200px',
                  background: 'transparent',
                  // outline: '2px solid #118cfc',
                  // borderRadius: '50%',
                  margin: 'auto'
                }}
                className='badge-border'
              >
                <Badge
                  sx={{ height: "100%", maxWidth: "100%", cursor: "pointer" }}
                  badgeContent={<img alt='CloseIcon' width={22} height={22} src={CloseIcon} />}
                  color="error"
                  onClick={removeImage}
                >
                  <img
                    className='primary'
                    alt='url'
                    style={{
                      maxWidth: '100%',
                      height: '100%',
                      // padding: '5px'
                      borderRadius: 5
                    }}
                    src={selectedImage === 'https://cdn.botpenguin.com/assets/assets/icons/botpenguin-avatar.png'
                      ? agency?.type === 'WHITE_LABEL'
                        ? agency.meta.logo
                        : 'https://cdn.botpenguin.com/assets/assets/icons/botpenguin-avatar.png'
                      : selectedImage}
                    onError={e => setSelectedImage(ImageIcon)}
                  />
                </Badge>

              </div>

            </div>
            {/* 
                <div>
                  <div className='mx-0 my-auto uploadimag'>
                    <input id="upload-input" hidden={true} type="file" accept="image/png, image/jpeg, image/jpg" className="file-replace-input" name="images" onChange={(e) => uploadSelectedImage(e)} />
                    <button style={{ marginTop: '20px' }} className='btn btn-primary theme-button' onClick={e => { e.preventDefault(); showFolder(); }}>Replace</button>
                  </div>
                </div> */}
          </>
          : <>
            <div className="uploadimag">
              <input type="file" accept="image/png, image/jpeg, image/jpg" className="file-input" name="images" onChange={(e) => uploadSelectedImage(e)} />
              <div>
                <div style={{ marginTop: '-100px' }}>
                  <span className="material-icons text-primary">
                    cloud_upload
                  </span>
                </div>
                <div>
                  <p><span className="text-primary">{staticTexts['clickHere']} </span> {staticTexts['toUpload']}</p>
                </div>
              </div>
            </div>
          </>
        }
      </div>

      <Divider className='mb-3'>OR</Divider>

      <div className="custom-input-box input-group position-relative">
        <label>{staticTexts['insertDynamicText']}</label>
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          width:"100%"
        }}
        >
          <TextField
            size="small"
            fullWidth
            className="form-control theme-textarea"
            defaultValue={selectedImage}
            {...methods.register("source")}
            onChange={(e) => {
              setSelectedImage(e.target.value)
              methods.setValue("source", e.target.value)
            }}
            onKeyUp={e => {
              if (e.key === "/") {
                setState(prev => ({ ...prev, slashPressed: true }));
              } else {
                setState(prev => ({ ...prev, slashPressed: false }));
              }
            }}
            InputProps={{
              className: "theme-input",
              sx: {
                fontSize: 14,
                padding: 0
              }
            }}
            InputLabelProps={{
              sx: {
                fontSize: 14,
              }
            }}
          />
          {
            botType === "whatsapp" && <FormHelperText id="my-helper-text" className="pt-2">
              <div className="helper-text"><img src={InfoIcon} alt='info' /> {staticTexts['textSlashText']}</div>
            </FormHelperText>
          }
        </div>
        {
          state.slashPressed ?
            <div
              className="custom-dropdown slash-dropdown">
              <ul style={{ transform: "translate3d(1px, -330px, 0px)", width: "100%", maxHeight: 300 }}>
                <div className="add-btn-section-head">{staticTexts["defaultAttributes"]}</div>

                <div className="attribute-name" onClick={() => addVariable("{{name}}")}>{staticTexts["nameText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{whatsappName}}")}>{staticTexts["whatsappName"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{email}}")}>{staticTexts["emailText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{phone}}")}>{staticTexts["phoneNumberText"]}</div>
                <div className="attribute-name" onClick={() => addVariable("{{whatsAppNumber}}")}>{staticTexts["whatsappNumber"]}</div>

                {attributes?.length ? <div className="add-btn-section-head">{staticTexts["customAttributes"]}</div> : null}
                {
                  (attributes)?.map((attribute, i) => {
                    return <div key={i} className="attribute-name" onClick={() => addVariable(`{{${attribute.key}}}`)}>{attribute.displayName}</div>
                  })
                }
              </ul>
            </div>
            :
            null
        }
      </div>

    </>
  );
}