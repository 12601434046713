import './index.scss';
import multi_select from '../../../../assets/images-v2/multi_select.png';
import file from '../../../../assets/images-v2/file.png';
import appointment from '../../../../assets/images-v2/appointment.png';
//import date from '../../../../assets/images-v2/date.png';
//import image from '../../../../assets/images-v2/image.png';
import contact from '../../../../assets/images-v2/contact.png';
import rating from '../../../../assets/images-v2/rating.png';
import range from '../../../../assets/images-v2/range.png';
import video from '../../../../assets/images-v2/video.png';
import live_chat from '../../../../assets/images-v2/live_chat.png';
import redirect from '../../../../assets/images/chat-components/redirect.svg';
import { Chip, Tooltip } from '@mui/material';
import single_product from '../../../../assets/images-v2/single_product.svg';
import multi_product from '../../../../assets/images-v2/multi_product.svg';
import template from '../../../../assets/images-v2/template.png';
import { copyIcon, deleteIcon } from '../../../../assets/icons';
import { getStaticText } from '../../../../assets/json/component-static-text/index';
import { getTooltipText } from '../../../../assets/json/component-tooltip/index';
import { UPDATE_DIALOGUE } from '../../../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import {
  personIcon as name,
  messageIcon as statement,
  emailIcon as email,
  chatgptIcon as AI,
  thumbsUpIcon as button,
  phoneNumber as phone,
  textIcon as question,
  location,document,smartQuestion as smart_question,catalogue,address,numericInput as number,
  api,
  image,
  clock as time,
  calendar as date

} from "../../../../assets/icons";
import { getTextByLanguage } from "../../../../utilities/helper";
import { useSelector } from "react-redux";

const images = {
  name,
  date,
  file,
  email,
  phone,
  image,
  range,
  video,
  rating,
  number,
  contact,
  question,
  location,
  redirect,
  catalogue,
  statement,
  live_chat,
  button,
  appointment,
  multi_select,
  smart_question,
  document,
  AI,
  address,
  single_product,
  multi_product,
  template,
  api,
  date,
  time
};

export default function ChatBubble({
  quickActions,
  isSelected,
  type,
  text,
  hasUserReply,
  index,
  id,
  moveCard,
  saveDropResult,
  setActiveQuestion,
  question,
  copyQuestion,
}) {
  const dispatch = useDispatch();
  const language = useSelector((state)=> state.language.lang);
  const staticTexts = getStaticText(language);
  const tooltipTexts = getTooltipText();

  return (
    <>
      <div className="dnd">
        <div className="d-flex">
          {/* Quick Action */}
          <div
            className={`d-flex flex-column justify-content-end`}
            style={{ opacity: quickActions === id ? 1 : 0 }}
          >
            <div className="edit-question-parent">
              <div className="edit-q-options">
                <div
                  className="edit-q-hover"
                  onClick={() => copyQuestion(question)}
                >
                  <Tooltip title={tooltipTexts.copyQuestion}>
                    <img src={copyIcon} alt="copy-icon" />
                  </Tooltip>
                </div>
                <div
                  className="edit-q-hover"
                  onClick={() =>
                    dispatch({
                      type: UPDATE_DIALOGUE,
                      payload: {
                        deleteNodeDialogue: true,
                        deleteNodeData: question,
                      },
                    })
                  }
                >
                  <Tooltip title={tooltipTexts.deleteQuestion}>
                    <img src={deleteIcon} alt="delete-icon" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          {/* Image */}
          <span className="d-flex flex-column justify-content-end bubble-icons">
            <img
              alt="img"
              src={images[type]}
              style={{ maxWidth: '100%' }}
            ></img>
          </span>

          {/* Question Label */}
          <div className={`chat left-chat`}>
            <p style={{ margin: 0, paddingLeft: '4px' }}>{getTextByLanguage(language,text) ?? ""}</p>
          </div>
        </div>
        {/* 
        {
          
        } */}
        {question.type === 'button' ||
        question.type === 'multi_select' ||
        question.type === 'api' ? (
          <div style={{ marginTop: '8px', marginLeft: 45 }}>
            {question?.options?.map((option, i) =>  (
                  option !== undefined && (
                    <div className={`left-chat-option`}>
                    <Chip
                      className="cursor-pointer"
                      label={ question.type === 'button' ? getTextByLanguage(language, option.values) : option.value}
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                  )))
                }
          </div>
        ) : (
          hasUserReply && (
            <div style={{ marginTop: '8px' }}>
              <div className={`chat right-chat`}>
                <p style={{ margin: 0 }}>{staticTexts['userReply']}</p>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}
