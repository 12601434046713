import axios from 'axios';
axios.defaults.responseType = 'json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = {
  'Content-Type': 'application/json',
};

const httpRequest = async (
  method,
  url,
  data,
  headers = {
     "Authorization": `Bearer ${localStorage.getItem("token") || ""}`,
    //TODO: Comment static and enable dynamic token value
   // "Authorization": `Bearer eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTMzYmE0MWM5ZDA1MjQwMDJmNzA1OWUiLCJfY3JlZGVudGlhbCI6IjY1MzNiYTQxYzlkMDUyNDAwMmY3MDVhMSIsIm5hbWUiOiJBZG1pbiIsInVzZXJUeXBlIjoiQURNSU4iLCJqdGlJZCI6IjZhZTg5YmExLTExOGEtNDM2YS1hOGNjLTQ1NjVlNGM5MmFlOCIsImlhdCI6MTY5OTUwOTM4NiwiZXhwIjoxNzAyMTAxMzg2LCJhdWQiOiJ0cnlrYXJkaS5jb20iLCJpc3MiOiJ0cnlrYXJkaS5jb20iLCJzdWIiOiJtYXlhbmtAcmVsaW5ucy5jb20iLCJqdGkiOiI2YWU4OWJhMS0xMThhLTQzNmEtYThjYy00NTY1ZTRjOTJhZTgifQ.Bmei8pz29j8hWn4u1-vixUkBAkMbycyxTI1CygfRMPWvpkk9tY4kHBYwadLxMHujoLtr2nbqDSDwqOQaaTfilYiLg_CMZhCqKhWSbNidmuGNoJ5TPLCR7vxAyaXS9FWtmGtyRphpZL2gN7mrE__gGSxg-RrLpFBNnHYlaX-DezvGAPs7meAP-L06DQqyCKLX8G7T7fqsKYGB38mlYjT_flRjdjkM03WRBnZVfkfZ82VkSC-Ky_B6AuTTrqsJPa4XCbRT-zRGey_1ctKMPmqwillQNHpQdvxOLkkY0zLtyMQEWwzVlhGSBL_JbwyacRMyWmH3kP_9-5cVBb4U4KOXeA`,
    "Content-Type": "application/json",
  }
) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      headers
    })
      .then(response => {
        if (String(response.status).match(/20/gi)) {
          resolve(response.data);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        reject(err);
      })
  });


export { httpRequest };
